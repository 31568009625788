import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import * as React from 'react'

interface Props {
  forbidRemove?: boolean
  onEdit: () => void
  onRemove: () => void
}

export const TableCellActions: React.FC<Props> = props => {
  const { forbidRemove, onEdit, onRemove } = props

  return (
    <Space size="middle">
      <Tooltip title="Редактировать">
        <Button icon={<EditOutlined />} size="middle" onClick={onEdit} />
      </Tooltip>
      <Tooltip title="Удалить">
        <Button icon={<CloseOutlined />} size="middle" danger onClick={onRemove} disabled={forbidRemove} />
      </Tooltip>
    </Space>
  )
}

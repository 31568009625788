import { api } from '@api/api'
import { useState } from 'react'
import { Button, Table, notification } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { formatRublesToPennies } from '@format/number.format'

import { genGoodsTableColumns } from '../goods-columns.utils'
import { EditGood } from '../modals/edit-good'
import { DeleteModal } from '../modals/delete-modal'
import { ConsignmentsSubtable } from '../components/consignments-subtable'

export const GoodsTable = (props: any) => {
  const { categories, goods, isLoading, getAllGoods, onExpandGoods } = props
  const [good, setGood] = useState({} as any)
  const [isEditModalOpened, setIsEditModalOpened] = useState(false)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)

  const onOpenModal = (row: any, method: 'delete' | 'edit') => {
    setGood(row)
    if (method === 'delete') {
      setIsDeleteModalOpened(true)
    } else {
      setIsEditModalOpened(true)
    }
  }

  const onDelete = async () => {
    await api.productsGoodsService.remove(good.id).catch(error =>
      notification.error({
        message: `Произошла ошибка при удалении товара`,
        description: `Причина: ${error?.response?.data?.message ?? ''}`,
      })
    )
    await getAllGoods()
    setIsDeleteModalOpened(false)
  }

  const columns = genGoodsTableColumns(onOpenModal)

  const updateGood = async (event: any) => {
    await api.productsGoodsService.update(good.id, {
      ...event,
      cost: formatRublesToPennies(event.cost),
    })
    await getAllGoods()
    setIsEditModalOpened(false)
  }

  const customExpand = (props: any) => {
    if (props.expanded) {
      return <Button icon={<MinusOutlined />} onClick={e => props.onExpand(props.record, e)} type="primary" ghost />
    } else {
      return <Button icon={<PlusOutlined />} onClick={e => props.onExpand(props.record, e)} type="primary" ghost />
    }
  }

  return (
    <>
      <EditGood
        categories={categories}
        onFinish={updateGood}
        good={good}
        isOpen={isEditModalOpened}
        onCancel={() => {
          setIsEditModalOpened(false)
        }}
      />
      <DeleteModal onCancel={() => setIsDeleteModalOpened(false)} isOpen={isDeleteModalOpened} onDelete={onDelete} />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={goods.content}
        loading={isLoading}
        expandable={{
          expandedRowRender: ConsignmentsSubtable,
          expandIcon: customExpand,
          onExpand: onExpandGoods,
        }}
      />
    </>
  )
}

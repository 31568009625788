import { FC, useEffect, useState } from 'react'
import { Col, Flex, Row, Tag } from 'antd'
import { formatNameColorsToHex } from '@utils/colors.utils'

import { StudioCollapse } from './ui/studio-collapse/studio-collapse.component'
import { IHandleCurrentStudio, IStudiosCollapsesProps } from './studios-collapses.types'
import { FIGURE_TYPE } from '../../constants/figure'
import { TAG_COLORS, TAG_TEXTS, TAG_TYPES } from '../../constants/tag'
import { getDefaultFigurePreset } from '../../utils/figure'

import './studios-collapses.styles.less'

export const StudiosCollapse: FC<IStudiosCollapsesProps> = ({
  studios,
  setCurrentStudio,
  setFigurePreset,
  defaults,
}) => {
  // SECTION: State
  const [isInitDefaults, setIsInitDefaults] = useState(false)

  const [currentTagId, setCurrentTagId] = useState<null | string>(null)
  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>([])

  // SECTION: Helpers
  const getTagAllStudiosColor = (currentTagId: string | null) => {
    if (!currentTagId) return TAG_COLORS.ALL_STUDIOS
    return currentTagId === TAG_TYPES.ALL_STUDIOS ? TAG_COLORS.ALL_STUDIOS_ACTIVE : TAG_COLORS.ALL_STUDIOS
  }

  // SECTION: Actions
  const handleUpdateActiveKeys = (key: string) => {
    if (activeCollapseKeys.includes(key)) {
      setActiveCollapseKeys(activeCollapseKeys.filter(id => id !== key))
    } else {
      setActiveCollapseKeys([...activeCollapseKeys, key])
    }
  }

  const handleCurrentStudio = ({ studio, room, tagType }: IHandleCurrentStudio) => {
    let figurePreset = getDefaultFigurePreset()

    if (tagType === TAG_TYPES.ALL_STUDIOS) {
      figurePreset.figure.type = FIGURE_TYPE.SQUARE
      figurePreset.figure.color = TAG_COLORS.ALL_STUDIOS
      figurePreset.details.color = TAG_COLORS.ALL_STUDIOS_DETAILS
      figurePreset.details.name = TAG_TEXTS.ALL_STUDIOS
      figurePreset.tag.type = TAG_TYPES.ALL_STUDIOS

      setCurrentTagId(TAG_TYPES.ALL_STUDIOS)
      setCurrentStudio(null)
    }

    if (tagType === TAG_TYPES.ALL_ROOMS && studio) {
      figurePreset.figure.type = FIGURE_TYPE.SQUARE
      figurePreset.figure.color = TAG_COLORS.ALL_ROOMS
      figurePreset.details.color = TAG_COLORS.ALL_ROOMS_DETAILS
      figurePreset.details.name = TAG_TEXTS.ALL_ROOMS
      figurePreset.tag.type = TAG_TYPES.ALL_ROOMS

      setCurrentTagId(studio.id)
      setCurrentStudio({
        id: studio.id,
        name: studio.name,
        selectedRoom: null,
        workTime: studio.workTime.dailyWorkTime,
      })
    }

    if (studio && room) {
      figurePreset.figure.type = FIGURE_TYPE.CIRCLE
      figurePreset.figure.color = formatNameColorsToHex(room.color, 5)
      figurePreset.details.color = formatNameColorsToHex(room.color, 1)
      figurePreset.details.name = room.name
      figurePreset.tag.type = TAG_TYPES.ONE_ROOM

      setCurrentTagId(room.id)
      setCurrentStudio({
        id: studio.id,
        name: studio.name,
        selectedRoom: room,
        workTime: studio.workTime.dailyWorkTime,
      })
    }

    setFigurePreset(figurePreset)
  }

  // SECTION: Lifecycle
  useEffect(() => {
    if (defaults && !isInitDefaults) {
      setActiveCollapseKeys(defaults.activeKeys)
      setCurrentTagId(defaults.currentTagId)
      setIsInitDefaults(true)
    }
  }, [defaults])

  // SECTION: Render
  return (
    <Flex vertical gap="middle" className="studios-collapses">
      <Row gutter={[8, 8]} justify="space-between">
        {studios.map((studio, index) => (
          <Col key={studio.id} span={8}>
            {index === 0 ? (
              <Flex gap="middle">
                <Flex align="start" justify="start" className="studios-collapses__all-studios">
                  <Tag
                    color={getTagAllStudiosColor(currentTagId)}
                    onClick={() => handleCurrentStudio({ tagType: TAG_TYPES.ALL_STUDIOS })}
                  >
                    {TAG_TEXTS.ALL_STUDIOS}
                  </Tag>
                </Flex>

                <StudioCollapse
                  key={studio.id}
                  activeKeys={activeCollapseKeys}
                  addActiveKey={handleUpdateActiveKeys}
                  studio={studio}
                  handleCurrentStudio={handleCurrentStudio}
                  currentTagId={currentTagId}
                />
              </Flex>
            ) : (
              <StudioCollapse
                key={studio.id}
                activeKeys={activeCollapseKeys}
                addActiveKey={handleUpdateActiveKeys}
                studio={studio}
                handleCurrentStudio={handleCurrentStudio}
                currentTagId={currentTagId}
              />
            )}
          </Col>
        ))}
      </Row>
    </Flex>
  )
}

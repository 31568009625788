import * as React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Tag } from 'antd'
import { TypeTagFormat } from '@components/ui/type-tag-format/ui/type-tag-format.component'

import { ExercisesTypesTableActions, ExercisesTypesTableRow } from './exercises-types-table.types'
import { ExercisesTypesTableColumnActions } from './exercises-types-table-column-actions/exercises-types-table-column-actions.component'

export function genExercisesTypesTableColumns(params: ExercisesTypesTableActions): ColumnsType<ExercisesTypesTableRow> {
  const { onEdit, onRemove } = params

  return [
    {
      title: 'Название типа записей',
      dataIndex: 'name',
      key: 'name',
      render: name => name || '',
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      render: text => text || '-',
    },
    // {
    //   title: 'Формат',
    //   dataIndex: 'personal',
    //   key: 'personal',
    //   render: (_, record) => (
    //     <Tag color={record.personal ? 'geekblue' : 'pink'}>
    //       {record.personal ? 'Персональные услуги' : 'Групповые услуги'}
    //     </Tag>
    //   ),
    // },
    {
      title: 'Формат',
      dataIndex: 'format',
      key: 'format',
      render: (_, record) => <TypeTagFormat typeFormat={record.format} />,
    },
    {
      title: 'Показывать пользователям в расписании',
      dataIndex: 'showToUser',
      key: 'showToUser',
      render: showToUser => {
        if (showToUser === false) return 'Нет'
        else if (showToUser === true) return 'Да'
        else return '-'
      },
    },
    {
      title: 'Операции',
      key: 'action',
      render: (_, record) => <ExercisesTypesTableColumnActions {...record} onEdit={onEdit} onRemove={onRemove} />,
    },
  ]
}

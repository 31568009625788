import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { Flex } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { genSchedulePageListTimetable } from '@store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'

import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { genHomePagePath, genSchedulePagePath } from '../../format/path.format'
import { isDef } from '../../types/lang.types'
import { SchedulePageFilter } from './schedule-page-filter/schedule-page-filter.component'
import { SchedulePageTable } from './schedule-page-table/schedule-page-table.component'
import { useSchedulePage } from './schedule-page.hook'
import { useSchedulePageTable } from './schedule-page-table/schedule-page-table.hook'
import './schedule-page-table.styles.less'

export const SchedulePage: React.FC = () => {
  const { studioId, selectedStudioId, isLoaded, isLoading } = useSchedulePage()
  const schedulePageData = useSchedulePageTable()

  const currentTimetable = useSelector(genSchedulePageListTimetable)

  if (!isDef(studioId)) {
    if (isDef(selectedStudioId)) {
      return <Redirect to={genSchedulePagePath(selectedStudioId)} />
    }

    return <Redirect to={genHomePagePath()} />
  }

  if (!isLoaded || isLoading) {
    return <PageLoader />
  }

  const totalClientsCounts = (schedulePageData?.schedule ?? []).reduce(
    (total, event) => total + (event.clientsCounts ?? 0),
    0
  )
  const totalMaxClientsCount = (schedulePageData?.schedule ?? []).reduce(
    (total, event) => total + (event.maxClientsCount ?? 0),
    0
  )

  return (
    <>
      {currentTimetable === 'daily' && (
        <Flex gap="10px" align="center" className="header">
          <p className="header__date">
            {dayjs(schedulePageData.date)
              .format('dddd, D MMMM')
              .replace(/^\p{L}/u, c => c.toUpperCase())}
          </p>

          <Flex className="header__guests" align="center" gap="2px">
            <TeamOutlined />
            {totalClientsCounts}/{totalMaxClientsCount}
          </Flex>
        </Flex>
      )}
      <SchedulePageFilter />
      <SchedulePageTable {...schedulePageData} />
    </>
  )
}

import { ExercisesTypesApi } from '@api/types/exercises-types-api.types'
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { ExercisesDirectionsApi } from '../../../../api/types/exercises-directions-api.types'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'
import { Nullable } from '../../../../types/lang.types'
import {
  ScheduleGroupPageEditExercisePayload,
  ScheduleGroupPageEditFetchPageDataSuccessPayload,
} from './schedule-group-page-edit.types'

export interface ScheduleGroupPageEditState {
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  studiosRooms: Nullable<StudiosRoomsApi.StudioRoom[]>
  directions: Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ScheduleGroupPageEditState = {
  trainers: null,
  studiosRooms: null,
  directions: null,
  exercisesTypes: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: scheduleGroupPageEditActions, reducer: scheduleGroupPageEditReducer } = createSlice({
  name: '@@schedule-group-page-edit',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<ScheduleGroupPageEditState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<ScheduleGroupPageEditState>,
      action: PayloadAction<ScheduleGroupPageEditFetchPageDataSuccessPayload>
    ) => {
      const { trainers, studiosRooms, directions, exercisesTypes } = action.payload

      state.trainers = trainers
      state.studiosRooms = studiosRooms
      state.directions = directions
      state.exercisesTypes = exercisesTypes

      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<ScheduleGroupPageEditState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    editExercise: (
      state: Draft<ScheduleGroupPageEditState>,
      _: PayloadAction<ScheduleGroupPageEditExercisePayload>
    ) => {
      state.isLoading = true
    },
    editExerciseSuccess: (state: Draft<ScheduleGroupPageEditState>) => {
      state.isLoading = false
    },
    editExerciseError: (state: Draft<ScheduleGroupPageEditState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    removeExercise: (state: Draft<ScheduleGroupPageEditState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    removeExerciseSuccess: (state: Draft<ScheduleGroupPageEditState>) => {
      state.isLoading = false
    },
    removeExerciseError: (state: Draft<ScheduleGroupPageEditState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

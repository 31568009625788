// @ts-nocheck
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FC, useEffect, useMemo, useState } from 'react'
import { Button, Flex, Typography, Card, Form, Collapse, Alert } from 'antd'
import { EMPLOYEE_SCHEDULE_STATUS, EMPLOYEE_SCHEDULE_FOR_ENTITY } from '@constants/employees/schedule'
import { EmployeeScheduleMainInfoForm } from '@components/employees/schedule/main-info-form/main-info-form.component'
import { ModalConfirmBack } from '@components/modals/modal-confirm-back/modal-confirm-back.component'
import { useModal } from '@hooks/use-modal.hook'
import { employeesScheduleEditActions } from '@store/pages/employees/employees-schedule-edit/employees-schedule-edit.slice'
import {
  getEmployeesScheduleEdit,
  getEmployeesScheduleEditIsLoading,
} from '@store/pages/employees/employees-schedule-edit/employees-schedule-edit.selectors'
import { initEmployeeScheduleForm } from '@utils/form/init-employee-schedule-form'
import { employeesScheduleActions } from '@store/pages/employees/employees-schedule/employees-schedule.slice'
import { DeleteOutlined, VideoCameraTwoTone } from '@ant-design/icons'
import { ModalConfirmDelete } from '@components/modals/modal-confirm-delete/modal-confirm-delete.component'
import { FinalScheduleTable } from '@components/employees/schedule/final-schedule-table/final-schedule-table.component'
import { WorkTimeSchedule } from '@components/employees/schedule/work-time-schedule/work-time-schedule.component'
import { IWorkTimeScheduleStudio } from '@components/employees/schedule/work-time-schedule/work-time-schedule.types'
import { useTimeSlotsFromFinalScheduleStudios } from '@hooks/employees/schedule/use-time-slots-from-final-schedule-studios'
import { transformScheduleStudioToFinalScheduleStudio } from '@components/employees/schedule/final-schedule-table/utils/transform'

import { Breadcrumbs } from './ui/breadcrumbs/breadcrumbs.component'
import { IEmployeeScheduleEditParams } from './employees-schedule-edit.types'

import './employees-schedule-edit.styles.less'

export const EmployeesScheduleEditPage: FC = () => {
  // SECTION: Hooks
  const { id } = useParams<IEmployeeScheduleEditParams>()
  const history = useHistory()

  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const employeeNameWatch = Form.useWatch('employee', form)
  const positionNameWatch = Form.useWatch('position', form)

  // SECTION: Store
  const schedule = useSelector(getEmployeesScheduleEdit)
  const loading = useSelector(getEmployeesScheduleEditIsLoading)

  // SECTION: State
  const [schedulesStudios, setSchedulesStudios] = useState<IWorkTimeScheduleStudio[]>([])
  const [error, setError] = useState<string | null>(null)

  // SECTION: Computed
  const totalScheduleName = useMemo(() => {
    const formValues = form.getFieldsValue()
    let name = ''

    switch (formValues.scheduleForEntity) {
      case EMPLOYEE_SCHEDULE_FOR_ENTITY.EMPLOYEE:
        name = formValues.employee
        break
      case EMPLOYEE_SCHEDULE_FOR_ENTITY.POSITION:
        name = formValues.position
        break
    }

    return `Итоговое расписание для ${name}`
  }, [employeeNameWatch, positionNameWatch])

  const finalScheduleStudios = useMemo(
    () => transformScheduleStudioToFinalScheduleStudio(schedulesStudios),
    [schedulesStudios]
  )

  // SECTION: Actions
  const handleChangeWorkTimeSchedule = (schedules: IWorkTimeScheduleStudio[]) => setSchedulesStudios(schedules)

  const editSchedule = async () => {
    let hasError = false
    await form.validateFields().catch(err => {
      setError('Заполните все поля!')
      hasError = true
    })
    if (Object.keys(timeRangeSlot).length === 0 && !hasError) {
      setError('Заполните расписание!')
      hasError = true
    }

    if (hasError) return

    const formValues = form.getFieldsValue()

    const schedule = {
      employeeId: null,
      employeePositionId: null,
      validFrom: formValues.schedulePeriod[0].format('YYYY-MM-DD'),
      validTo: formValues.schedulePeriod[1].format('YYYY-MM-DD'),
      status: EMPLOYEE_SCHEDULE_STATUS.DRAFT,
      slots: {
        dailyWorkTime: timeRangeSlot,
      },
    }

    switch (formValues.scheduleForEntity) {
      case EMPLOYEE_SCHEDULE_FOR_ENTITY.EMPLOYEE:
        schedule.employeeId = formValues.employeeId
        break
      case EMPLOYEE_SCHEDULE_FOR_ENTITY.POSITION:
        schedule.employeePositionId = formValues.employeePositionId
        break
    }

    if (!schedule) return
    dispatch(
      employeesScheduleEditActions.edit({
        id,
        schedule,
        successCallback: () => history.push('/employees/schedule'),
      })
    )
  }

  const back = () => history.go(-1)
  const deleteSchedule = () => dispatch(employeesScheduleActions.delete({ scheduleId: id }))

  // SECTION: Custom hooks
  const [
    isShowModalConfirmDelete,
    handleOpenModalConfirmDelete,
    handleCloseModalConfirmDelete,
    handleOkModalConfirmDelete,
  ] = useModal(deleteSchedule)

  const [isShowModalConfirmBack, handleOpenModalConfirmBack, handleCloseModalConfirmBack, handleOkModalConfirmBack] =
    useModal(back)

  // SECTION: Effects
  useEffect(() => {
    initEmployeeScheduleForm(schedule, form)
  }, [schedule])

  useEffect(() => {
    dispatch(employeesScheduleEditActions.fetchById({ scheduleId: id }))
  }, [])

  const timeRangeSlot = useTimeSlotsFromFinalScheduleStudios(finalScheduleStudios)

  return (
    <Flex gap="large" vertical>
      <Breadcrumbs />
      <Flex gap="small" justify="space-between" align="center" className="employees-schedule-edit__header">
        <Typography.Title className="employees-schedule-edit__title" level={2}>
          Редактирование расписания
        </Typography.Title>

        <Button type="primary" danger icon={<DeleteOutlined />} onClick={handleOpenModalConfirmDelete}>
          Удалить
        </Button>
      </Flex>

      <Card>
        <Flex vertical gap="small">
          <Typography.Title level={4} className="employees-schedule-edit__title">
            Основная информация
          </Typography.Title>

          <EmployeeScheduleMainInfoForm form={form} />
        </Flex>
      </Card>

      <Card>
        <Flex vertical gap="middle">
          <Flex gap="small" align="center">
            <Typography.Title level={4} className="employees-schedule-edit__title">
              Настройка графика
            </Typography.Title>

            <Button
              className="employees-schedule-edit__button-guide"
              icon={<VideoCameraTwoTone />}
              size="small"
              type="dashed"
            >
              Как заполнять график?
            </Button>
          </Flex>

          <WorkTimeSchedule
            isLoading={loading}
            onChange={handleChangeWorkTimeSchedule}
            defaultWorkTime={schedule?.slots?.dailyWorkTime}
          />
        </Flex>
      </Card>

      <Collapse
        className="employees-schedule-edit__collapse"
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: (
              <Typography.Title level={5} className="employees-schedule-edit__title">
                {totalScheduleName}
              </Typography.Title>
            ),
            children: <FinalScheduleTable finalScheduleStudios={finalScheduleStudios} />,
          },
        ]}
      />

      {error && <Alert message={error} type="error" showIcon />}

      <Flex gap="small" justify="flex-end">
        <Button onClick={handleOpenModalConfirmBack}>Закрыть</Button>
        <Button type="primary" onClick={editSchedule}>
          Сохранить
        </Button>
      </Flex>

      <ModalConfirmDelete
        isModalOpen={isShowModalConfirmDelete}
        handleOk={handleOkModalConfirmDelete}
        handleCancel={handleCloseModalConfirmDelete}
      />
      <ModalConfirmBack
        isModalOpen={isShowModalConfirmBack}
        handleOk={handleOkModalConfirmBack}
        handleCancel={handleCloseModalConfirmBack}
      />
    </Flex>
  )
}

import * as React from 'react'
import { Button, Col, Row } from 'antd'
import { Link } from 'react-router-dom'

import { genExercisesTypesCreatePagePath } from '../../../format/path.format'

export const ExercisesTypesPageTopBar = () => {
  return (
    <Row justify="start" align="middle" style={{ marginBottom: '20px' }}>
      <Col>
        <Link to={genExercisesTypesCreatePagePath()} className="menu-item">
          <Button type="primary">Добавить тип записей</Button>
        </Link>
      </Col>
    </Row>
  )
}

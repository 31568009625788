import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useMasterServicesPageParams } from './master-services-page-hooks/master-services-page-params.hook'
import { masterServicesPageActions } from '../../store/pages/master-services-page/master-services-page.slice'
import {
  genMasterServicesIsLoaded,
  genMasterServicesIsLoading,
} from '../../store/pages/master-services-page/master-services-page.selectors'

export function useMasterServicesPage() {
  const params = useMasterServicesPageParams()

  const isLoading = useSelector(genMasterServicesIsLoading)
  const isLoaded = useSelector(genMasterServicesIsLoaded)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(masterServicesPageActions.fetchAllMasterServices(params))
  }, [dispatch, params])

  React.useEffect(() => {
    return () => {
      dispatch(masterServicesPageActions.reset())
    }
  }, [dispatch])

  return {
    isLoaded,
    isLoading,
  }
}

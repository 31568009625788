import * as React from 'react'
import { Button, Typography } from 'antd'

import { CustomCard } from '../../../components/custom-card/custom-card.component'
import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { ClientsTransactionsTable } from '../../../components/clients/clients-transactions-table/clients-transactions-table.component'
import { isDef } from '../../../types/lang.types'
import { useClientsEditPagePurchases } from './clients-edit-page-purchases.hook'
import './clients-edit-page-purchases.styles.less'

export const ClientsEditPagePurchases: React.FC = () => {
  const {
    data,
    pagination,
    isLoading,
    isLoaded,
    isUsedBookingLoading,
    usedBooking,
    transactionId,
    onClearTransactionIdHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
    onBarcodeHandler,
    onRefundSumHandler,
    onBookingHandler,
    onHoverUsedTransaction,
  } = useClientsEditPagePurchases()

  if (!isLoaded && isLoading) {
    return <PageLoader />
  }

  if (isDef(data)) {
    return (
      <CustomCard>
        <Typography.Title className="clients-edit-purchases__title" level={4}>
          Покупки
        </Typography.Title>

        {transactionId && (
          <div className="clients-edit-purchases__button">
            <Button onClick={onClearTransactionIdHandler} type="primary">
              Очистить фильтры
            </Button>
          </div>
        )}

        <ClientsTransactionsTable
          data={data}
          pagination={pagination}
          loading={isLoading}
          onChangePage={onChangePageHandler}
          onChangePageSize={onChangePageSizeHandler}
          onBarcode={onBarcodeHandler}
          onRefundSumHandler={onRefundSumHandler}
          onBooking={onBookingHandler}
          onHoverUsed={onHoverUsedTransaction}
          isUsedBookingLoading={isUsedBookingLoading}
          usedBooking={usedBooking}
        />
      </CustomCard>
    )
  }

  return <PageEmpty description="Покупки не найдены" />
}

import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { usePaginationPagesLimit } from '@hooks/use-pagination-pages-limit'

import { useMasterServicesPageParams } from '../master-services-page-hooks/master-services-page-params.hook'
import {
  genMasterServicesIsLoading,
  genMasterServicesTableRowList,
  genMasterServicesTotalElements,
} from '../../../store/pages/master-services-page/master-services-page.selectors'
import { genPaginationConfig } from '../../../utils/pagination.utils'
import { masterServicesPageActions } from '../../../store/pages/master-services-page/master-services-page.slice'
import { genMasterServicesEditPagePath, genMasterServicesPagePath } from '../../../format/path.format'

export function useMasterServicesPageTable() {
  const { push } = useHistory()

  const { page, size } = useMasterServicesPageParams()

  const dispatch = useDispatch()

  const masterServices = useSelector(genMasterServicesTableRowList)
  const totalElements = useSelector(genMasterServicesTotalElements)
  const isLoading = useSelector(genMasterServicesIsLoading)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])
  usePaginationPagesLimit(pagination, totalElements, genMasterServicesPagePath)

  const onEditHandler = React.useCallback(
    (id: string): void => {
      push(genMasterServicesEditPagePath(id.toString()))
    },
    [push]
  )

  const onRemoveHandler = React.useCallback(
    (id: string): void => {
      dispatch(masterServicesPageActions.removeMasterService(id))
    },
    [dispatch]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genMasterServicesPagePath({ page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genMasterServicesPagePath({ page, size: pageSize }))
    },
    [page, push]
  )

  return {
    masterServices,
    pagination,
    isLoading,
    onEditHandler,
    onRemoveHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}

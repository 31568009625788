import { Button, Flex, Form, FormInstance, Popover, Typography } from 'antd'
import { CopyOutlined, CodeOutlined } from '@ant-design/icons'
import './master-services-widget-form-preview-code.styles.less'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { isDef, NString } from '../../../../../types/lang.types'
import { genMasterServicesWidgetScript } from '../master-services-widget-form-preview.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  tenantKey: NString
}

export const MasterServicesWidgetFormPreviewCode: React.FC<Props> = props => {
  const { form, tenantKey } = props

  const generalValues = Form.useWatch('general', form)
  const designValues = Form.useWatch('design', form)

  const removeUndefinedValues = (obj: any) => {
    if (isDef(obj)) {
      return JSON.parse(
        JSON.stringify(obj, (key, value) => {
          return value === null ? undefined : value
        })
      )
    }
  }
  function clearEmpties(obj: any) {
    if (isDef(obj)) {
      for (var k in obj) {
        if (!obj[k] || typeof obj[k] !== 'object') {
          continue
        }

        clearEmpties(obj[k])
        if (Object.keys(obj[k]).length === 0) {
          delete obj[k]
        }
      }
      return obj
    }
  }

  const config = {
    ...(isDef(generalValues) && {
      masterServiceId: generalValues.masterServiceId,
      tenantKey: tenantKey,
      countryCode: generalValues.countryCode,
      currency: generalValues.currency,
      language: generalValues.language,
      bookingDays: generalValues.bookingDays,
      vocabulary: generalValues.vocabulary,
      ...(isDef(generalValues.staticWidgetMode) && { staticWidgetMode: generalValues.staticWidgetMode }),
      ...(isDef(generalValues.displaySteps) && { displaySteps: generalValues.displaySteps }),

      userContacts: {
        requestFirstName: generalValues.userContacts['requestFirstName'],
        firstNameRequired: generalValues.userContacts['firstNameRequired'],
        requestLastName: generalValues.userContacts['requestLastName'],
        lastNameRequired: generalValues.userContacts['lastNameRequired'],
      },
    }),
    ...(isDef(designValues) && {
      widgetStyles: clearEmpties(removeUndefinedValues(designValues)),
    }),
  }

  const [open, setOpen] = useState(false)

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  return (
    <Flex className="master-services-widget-form-preview-code__wrapper" gap="large" justify="center" align="center">
      <Typography.Text underline>Как устанавливать?</Typography.Text>
      <Flex gap="middle">
        <Popover
          content={
            <div style={{ wordBreak: 'break-all', maxWidth: '580px', maxHeight: '600px', overflow: 'scroll' }}>
              {genMasterServicesWidgetScript(config)}
            </div>
          }
          trigger="click"
          open={open}
          placement="bottom"
          onOpenChange={handleOpenChange}
        >
          <Button icon={<CodeOutlined />}>Показать код</Button>
        </Popover>
        <CopyToClipboard text={genMasterServicesWidgetScript(config)}>
          <Button type="primary" icon={<CopyOutlined />}>
            Скопировать код
          </Button>
        </CopyToClipboard>
      </Flex>
    </Flex>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { mapCountableProductsToTransactionsProductsTableDataItem } from '../../../../mapping/products.mapping'
import { PaymentMethod } from '../../../../types/payment.types'

export const genTransactionsCreatePageTableIsLoading = (state: AppState): boolean =>
  state.transactionsCreatePage.transactionsCreatePageTable.isLoading

export const getTransactionsCreatePageTableProducts = (state: AppState) =>
  state.transactionsCreatePage.transactionsCreatePageTable.products

export const getTransactionsCreatePageTableTrial = (state: AppState) =>
  state.transactionsCreatePage.transactionsCreatePageTable.trial

export const getTransactionsCreatePageTablePaymentMethod = (state: AppState) =>
  state.transactionsCreatePage.transactionsCreatePageTable.paymentMethod

export const getTransactionsCreatePageTableProductsTableDataItems = createSelector(
  getTransactionsCreatePageTableProducts,
  products => mapCountableProductsToTransactionsProductsTableDataItem(products)
)

export const getTransactionsCreatePageTotal = createSelector(
  getTransactionsCreatePageTableProducts,
  getTransactionsCreatePageTableTrial,
  getTransactionsCreatePageTablePaymentMethod,
  (products, trial, paymentMethod) => {
    if (paymentMethod !== PaymentMethod.NO_PAYMENT) {
      if (isDefAndNotEmpty(products) && trial) {
        const reduceResult = products.reduce<number>((acc, product) => {
          const discount = product.discount ?? 0

          const productTotalCost = product.entity.discountPrice
            ? (product.entity.discountPrice - discount) * product.count
            : (product.entity.cost - discount) * product.count

          return acc + productTotalCost
        }, 0)
        return reduceResult
      } else if (isDefAndNotEmpty(products) && !trial) {
        return products.reduce<number>((acc, product) => {
          const discount = product.discount ?? 0

          const productTotalCost = product.entity.trialCost
            ? (product.entity.trialCost - discount) * product.count
            : (product.entity.discountPrice - discount) * product.count

          return acc + productTotalCost
        }, 0)
      } else {
        return 0
      }
    } else {
      return 0
    }
  }
)

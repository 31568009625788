import { put, takeLatest } from 'redux-saga/effects'
import { callApi } from '@utils/sagas.utils'
import { api } from '@api/api'

import { employeesScheduleCreateActions } from './employees-schedule-create.slice'

export function* create(action: ReturnType<typeof employeesScheduleCreateActions.create>) {
  try {
    const { schedule, successCallback } = action.payload
    yield callApi(api.employeesScheduleService.create, schedule)

    yield put(employeesScheduleCreateActions.createSuccess())
    if (successCallback) successCallback()
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleCreateActions.createError(new Error()))
  }
}

export function* employeesScheduleCreateSagas() {
  yield takeLatest(employeesScheduleCreateActions.create, create)
}

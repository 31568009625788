import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { Nullable } from '../../../types/lang.types'
import { Pagination } from '../../../api/types/api.types'
import { MasterServicesApi } from '../../../api/types/master-services-api.types'
import { mapMasterServicesToMasterServicesTableRowList } from '../../../mapping/master-services.mapping'

const getMasterServicesInternal = (state: AppState): Nullable<Pagination<MasterServicesApi.MasterService>> =>
  state.masterServicesPage.masterServices

export const genMasterServicesIsLoading = (state: AppState): boolean => state.masterServicesPage.isLoading
export const genMasterServicesIsLoaded = (state: AppState): boolean => state.masterServicesPage.isLoaded

export const genMasterServicesTableRowList = createSelector(getMasterServicesInternal, masterServices =>
  mapMasterServicesToMasterServicesTableRowList(masterServices?.content)
)

export const genMasterServicesTotalElements = createSelector(
  getMasterServicesInternal,
  masterServices => masterServices?.totalElements
)

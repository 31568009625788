import { TimePicker } from 'antd'
import { clsx } from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useCallback } from 'react'

import { formatDateToMilliseconds } from '../../../format/date.format'
import { isDef } from '../../../types/lang.types'
import './input-time-range.styles.less'
import { InputTimeRangeProps } from './input-time-range.types'

export const InputTimeRange: FC<InputTimeRangeProps> = props => {
  // Props
  const { className } = props
  const { duration, value, placeholder } = props
  const { onChange } = props

  const [placeholderFrom, placeholderTo] = placeholder

  // Dayjs
  const DEFAULT_DATE = '1970-01-01T00:00'

  // TimePicker
  const from = isDef(value) ? value[0] : '00:00'
  const to = isDef(value) ? value[1] : '00:00'

  // Helpers
  const isValidTime = (time: string) => {
    const format = 'HH:mm'
    return dayjs(time, format).format(format) === time
  }

  // Actions
  const onChangeFromHandler = useCallback(
    (date: Dayjs): void => {
      if (isDef(onChange)) {
        const stringValue = dayjs(date ?? DEFAULT_DATE).format('HH:mm')
        if (isDef(duration)) {
          const start = formatDateToMilliseconds(stringValue)
          const end = formatDateToMilliseconds(duration)
          const time = start + end

          let result = dayjs.utc(time ?? DEFAULT_DATE).format('HH:mm')
          onChange([stringValue, result])
        } else {
          onChange([stringValue, to])
        }
      }
    },
    [duration, onChange, to]
  )

  const onChangeToHandler = useCallback(
    (date: Dayjs): void => {
      if (isDef(onChange)) {
        onChange([from, dayjs(date ?? DEFAULT_DATE).format('HH:mm')])
      }
    },
    [onChange, from]
  )

  return (
    <div className={clsx('input-time-range', className)}>
      <TimePicker
        style={{ width: 100 }}
        allowClear
        value={from && isValidTime(from) ? dayjs(from, 'HH:mm') : dayjs(DEFAULT_DATE, 'HH:mm')}
        placeholder={placeholderFrom}
        onChange={onChangeFromHandler}
        format="HH:mm"
      />
      <TimePicker
        style={{ width: 100 }}
        allowClear
        value={to && isValidTime(to) ? dayjs(to, 'HH:mm') : dayjs(DEFAULT_DATE, 'HH:mm')}
        placeholder={placeholderTo}
        onChange={onChangeToHandler}
        format="HH:mm"
      />
    </div>
  )
}

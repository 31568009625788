import * as React from 'react'

import { ExercisesTypesForm } from '../../../components/exercises-types/exercises-types-form/exercises-types-form.component'
import { useExercisesTypesCreatePageForm } from './exercises-types-create-page-form.hook'

export const ExercisesTypesCreatePageForm = () => {
  const { form, isLoading, directionsOptions, colorsOptions, onFinishHandler } = useExercisesTypesCreatePageForm()

  return (
    <ExercisesTypesForm
      form={form}
      isLoading={isLoading}
      directionsOptions={directionsOptions}
      submitText="Создание типа записей"
      colorsOptions={colorsOptions}
      onFinish={onFinishHandler}
    />
  )
}

import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'

import { api } from '../../../api/api'
import { genPaginationParamsDTO } from '../../../utils/pagination.utils'
import { getRouterState } from '../../common/router/router.selectors'
import { callApi } from '../../../utils/sagas.utils'
import { masterServicesPageActions } from './master-services-page.slice'
import { genMasterServicesPageParams } from '../../../pages/master-services-page/master-services-page.utils'

export function* fetchAllMasterServices(action: ReturnType<typeof masterServicesPageActions.fetchAllMasterServices>) {
  try {
    const { page, size } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.masterServicesService.fetchAll>> = yield callApi(
      api.masterServicesService.fetchAll,
      params
    )

    yield put(masterServicesPageActions.fetchAllMasterServicesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(masterServicesPageActions.fetchAllMasterServicesError(new Error()))
  }
}

export function* removeMasterService(action: ReturnType<typeof masterServicesPageActions.removeMasterService>) {
  try {
    yield callApi(api.masterServicesService.remove, action.payload)

    yield put(masterServicesPageActions.removeMasterServiceSuccess())

    const { location }: RouterState = yield select(getRouterState)

    const { search } = location
    const params = genMasterServicesPageParams(search)

    yield put(masterServicesPageActions.fetchAllMasterServices(params))
  } catch (e) {
    console.error(e)
    yield put(masterServicesPageActions.removeMasterServiceError(new Error()))
  }
}

export function* masterServicesPageSagas() {
  yield takeLatest(masterServicesPageActions.fetchAllMasterServices, fetchAllMasterServices)
  yield takeLatest(masterServicesPageActions.removeMasterService, removeMasterService)
}

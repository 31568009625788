import {
  mapExercisesTypesToExercisesFormExercisesTypesOptions,
  mapExercisesTypeToExercisesFormDirectionsOptions,
  mapExercisesTypeToExercisesFormMasterServicesOptions,
  mapMasterServiceToExercisesFormSubServicesOptions,
  mapTrainersToExercisesFormTrainersOptions,
} from '@components/exercises/exercises-form/exercises-form.utils'
import { Form } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ExercisesGroupEditFormValues,
  ExercisesGroupEditFormValuesDTO,
} from '../../../components/exercises-group/exercises-group-edit-form/exercises-group-edit-form.types'
import { useStudio } from '../../../hooks/use-studios.hook'
import {
  getScheduleGroupPageEditIsLoaded,
  getScheduleGroupPageEditIsLoading,
  getScheduleGroupPageEditStudiosRoomsOptions,
  getScheduleGroupPageEditTrainersOptions,
  getScheduleGroupPageEditTypesOptions,
} from '../../../store/pages/schedule-group-page/schedule-group-page-edit/schedule-group-page-edit.selectors'
import { scheduleGroupPageEditActions } from '../../../store/pages/schedule-group-page/schedule-group-page-edit/schedule-group-page-edit.slice'
import {
  genScheduleGroupPageExercise,
  genScheduleGroupPageExerciseFormValues,
} from '../../../store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'
import { isDef, isDefAndNotEmpty } from '../../../types/lang.types'
import { useScheduleGroupPageParams } from '../schedule-group-page-hooks/schedule-group-page-params.hook'
import { isScheduleGroupPageExerciseCompleted } from './schedule-group-page-edit.utils'

export function useScheduleGroupPageEdit() {
  const { studioOffset } = useStudio()
  const { studioId, scheduleId } = useScheduleGroupPageParams()
  const schedule = useSelector(genScheduleGroupPageExercise)

  const [form] = Form.useForm<ExercisesGroupEditFormValues>()

  const isCompleted = isScheduleGroupPageExerciseCompleted(schedule)

  const dispatch = useDispatch()

  const exerciseFormValues = useSelector(genScheduleGroupPageExerciseFormValues)

  const isLoading = useSelector(getScheduleGroupPageEditIsLoading)
  const isLoaded = useSelector(getScheduleGroupPageEditIsLoaded)

  const allTrainersOptions = useSelector(getScheduleGroupPageEditTrainersOptions)
  const studiosRoomsOptions = useSelector(getScheduleGroupPageEditStudiosRoomsOptions)
  // const directions = useSelector(getScheduleGroupPageEditDirectionsOptions)

  const exercisesOptions = useSelector(getScheduleGroupPageEditTypesOptions)

  const exercisesTypes = mapExercisesTypesToExercisesFormExercisesTypesOptions(exercisesOptions)
  const [directionsOptions, setDirectionsOptions] = useState<DefaultOptionType[] | undefined>([])
  const [masterServicesOptions, setMasterServicesOptions] = useState<DefaultOptionType[] | undefined>(undefined)
  const [subServicesOptions, setSubServicesOptions] = useState<DefaultOptionType[] | undefined>(undefined)

  const typeId = schedule?.type.id

  const [trainersOptions, setTrainersOptions] = useState<DefaultOptionType[] | undefined>([])
  useEffect(() => {
    if (typeId !== undefined) {
      setTrainersOptions(mapTrainersToExercisesFormTrainersOptions(allTrainersOptions, typeId))
    }
  }, [typeId, allTrainersOptions])

  useEffect(() => {
    const exercisesType = exercisesOptions?.find(exercisesType => exercisesType.id === typeId)
    if (isDefAndNotEmpty(exercisesType?.masterServices)) {
      const updatedMasterServicesOptions = exercisesType?.masterServices.map(masterService => {
        const updatedSubServices = masterService.subServices.filter(
          subService => subService.direction.id === schedule?.direction.id
        )
        return {
          ...masterService,
          subServices: updatedSubServices,
        }
      })
      const masterServicesWithOptions = updatedMasterServicesOptions
        ?.filter(option => option.subServices.length > 0)
        .map(option => option.id)

      form.setFieldValue('masterService', masterServicesWithOptions ? masterServicesWithOptions[0] : '')
      setMasterServicesOptions(mapExercisesTypeToExercisesFormMasterServicesOptions(exercisesType))
      const selectedMasterService = exercisesType?.masterServices?.find(
        masterService => masterService.id === masterServicesWithOptions?.[0]
      )
      if (selectedMasterService) {
        const subService = selectedMasterService.subServices.find(
          subService => subService.direction.id === schedule?.direction.id
        )
        if (subService) {
          form.setFieldValue('subService', subService.direction.id)
        }
        setSubServicesOptions(mapMasterServiceToExercisesFormSubServicesOptions(selectedMasterService))
      }
    } else {
      setMasterServicesOptions(undefined)
      setDirectionsOptions(mapExercisesTypeToExercisesFormDirectionsOptions(exercisesType))
    }
  }, [typeId, form, exercisesOptions])

  const onChangeMasterServiceHandler = useCallback(
    (value: string): void => {
      form.setFieldValue('subService', ' ')
      const masterService = exercisesOptions
        ?.find(exercisesType => exercisesType.id === typeId)
        ?.masterServices?.find(masterService => masterService.id === value)

      setSubServicesOptions(mapMasterServiceToExercisesFormSubServicesOptions(masterService))
    },
    [exercisesOptions, typeId]
  )
  const onChangeSubServicesHandler = (value: number): void => {
    const values = form.getFieldsValue()

    const subService = exercisesOptions
      ?.find(exercisesType => exercisesType.id === values.type)
      ?.masterServices?.find(masterService => Number(masterService.id) === values.masterService)
      ?.subServices.find(subService => subService.direction.id === value)

    if (isDef(subService)) {
      // setDirectionDuration(subService.direction.duration)
      form.setFieldsValue({
        ...values,
        subService: subService.direction.id,
      })
    }
  }

  useEffect((): void => {
    if (isLoaded && isDef(exerciseFormValues)) {
      form.setFieldsValue(exerciseFormValues)
    }
  }, [exerciseFormValues, form, isLoaded])

  useEffect(() => {
    if (isDef(schedule) && isDef(studioOffset)) {
      dispatch(scheduleGroupPageEditActions.fetchPageData(studioId))
    }
  }, [dispatch, schedule, studioId, studioOffset])

  useEffect(() => {
    return () => {
      dispatch(scheduleGroupPageEditActions.reset())
    }
  }, [dispatch])

  const onSaveHandler = useCallback(
    (exercisesGroupEditFormValuesDTO: ExercisesGroupEditFormValuesDTO): void => {
      if (isDef(schedule) && isDef(studioOffset)) {
        dispatch(
          scheduleGroupPageEditActions.editExercise({
            exerciseId: scheduleId,
            exercisesGroupEditFormValuesDTO,
          })
        )
      }
    },
    [dispatch, schedule, scheduleId, studioOffset]
  )

  const onCancelHandler = useCallback((): void => {
    dispatch(scheduleGroupPageEditActions.removeExercise(scheduleId))
  }, [dispatch, scheduleId])

  return {
    schedule,
    isCompleted,
    studioOffset,
    form,
    isLoading,
    trainersOptions,
    studiosRoomsOptions,
    directionsOptions,
    exercisesTypes,
    onSaveHandler,
    onCancelHandler,
    masterServicesOptions,
    subServicesOptions,
    onChangeMasterServiceHandler,
    onChangeSubServicesHandler,
  }
}

import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_CLIENT_ACTIVE_RECORDS_BROADCAST } from '@constants/broadcast'

import { genClientsEditPagePath, genScheduleGroupPagePath } from '../../../format/path.format'
import { genPaginationConfig } from '../../../utils/pagination.utils'
import { clientsEditPageBookingsActiveActions } from '../../../store/pages/clients-edit-page/clients-edit-page-bookings-active/clients-edit-page-bookings-active.slice'
import {
  getClientsEditPageBookingsActiveIsLoaded,
  getClientsEditPageBookingsActiveIsLoading,
  getClientsEditPageBookingsActiveTableDataItems,
  getClientsEditPageBookingsActiveTotalElements,
} from '../../../store/pages/clients-edit-page/clients-edit-page-bookings-active/clients-edit-page-bookings-active.selectors'
import { useClientsEditPageParams } from '../clients-edit-page-hooks/clients-edit-page-params.hook'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { ScheduleGroupPageUrlParams } from '../../schedule-group-page/schedule-group-page.types'
import { getEntityStudiosOptions } from '../../../store/common/entity/studio/studio.selectors'
import { studioEntityActions } from '../../../store/common/entity/studio/studio.slice'
import { PaymentType } from '../../../api/types/api.types'

export function useClientsEditPageBookingsActive() {
  const { id, section, page, size } = useClientsEditPageParams()

  const { push } = useHistory()
  const dispatch = useDispatch()

  const data = useSelector(getClientsEditPageBookingsActiveTableDataItems)
  const totalElements = useSelector(getClientsEditPageBookingsActiveTotalElements)

  const isLoading = useSelector(getClientsEditPageBookingsActiveIsLoading)
  const isLoaded = useSelector(getClientsEditPageBookingsActiveIsLoaded)

  const studiosOptionsInitial = useSelector(getEntityStudiosOptions)

  const studiosOptions = [
    {
      value: '',
      label: 'Все студии',
    },
    ...(studiosOptionsInitial || []),
  ]

  const [selectedStudio, setSelectedStudio] = React.useState<string>('')

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  React.useEffect(() => {
    dispatch(studioEntityActions.fetchAllStudios())
  }, [])

  React.useEffect(() => {
    const params = selectedStudio ? { studioId: selectedStudio } : {}

    const updateActiveRecords = () =>
      dispatch(
        clientsEditPageBookingsActiveActions.fetchBookings({
          clientId: id,
          page,
          size,
          ...params,
        })
      )

    updateActiveRecords()

    const bc = new BroadcastChannel(UPDATE_CLIENT_ACTIVE_RECORDS_BROADCAST)
    bc.onmessage = event => {
      const response = JSON.parse(event.data)
      if (response.command === 'update') {
        updateActiveRecords()
      }
    }

    return () => {
      bc.close()
    }
  }, [dispatch, id, page, size, selectedStudio])

  React.useEffect(() => {
    return () => {
      dispatch(clientsEditPageBookingsActiveActions.reset())
    }
  }, [dispatch])

  const onStudioChangeHandler = React.useCallback((value: string) => setSelectedStudio(value), [setSelectedStudio])

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      const queryParams = selectedStudio ? { page, size: pageSize, studioId: selectedStudio } : { page, size: pageSize }
      push(genClientsEditPagePath({ id, section }, queryParams))
    },
    [id, push, section, selectedStudio]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      const queryParams = selectedStudio ? { size: pageSize, studioId: selectedStudio } : { size: pageSize }
      push(genClientsEditPagePath({ id, section }, queryParams))
    },
    [id, push, section, selectedStudio]
  )

  const onVisitHandler = React.useCallback(
    (bookingId: string, exerciseId: string, checked: boolean): void => {
      dispatch(
        clientsEditPageBookingsActiveActions.changeBookingVisitingConfirmation({
          bookingId,
          exerciseId,
          confirm: checked,
        })
      )
    },
    [dispatch]
  )

  const onBarcodeHandler = React.useCallback(
    (bookingId: string): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.CLIENTS_EDIT_PAGE_BOOKINGS_ACTIVE_RECEIPTS_MODAL,
          props: { bookingId: bookingId },
        })
      )
    },
    [dispatch]
  )

  const onCancelHandler = React.useCallback(
    (bookingId: string, exerciseId: string): void => {
      dispatch(
        clientsEditPageBookingsActiveActions.cancelBooking({
          bookingId,
          exerciseId,
          reason: '',
        })
      )
    },
    [dispatch]
  )

  const onBookingHandler = React.useCallback(
    (studioId: string, scheduleId: string): void => {
      const params: ScheduleGroupPageUrlParams = { studioId, scheduleId }
      push(genScheduleGroupPagePath(params))
    },
    [push]
  )

  const onChangePaymentTypeHandler = React.useCallback(
    (exerciseId: string, paymentType: PaymentType, bookingId: string): void => {
      dispatch(
        clientsEditPageBookingsActiveActions.changeBookingPaymentType({
          exerciseId,
          paymentType,
          bookingId,
        })
      )
    },
    [dispatch]
  )

  return {
    data,
    pagination,

    studiosOptions,

    isLoading,
    isLoaded,

    onChangePageHandler,
    onChangePageSizeHandler,
    onStudioChangeHandler,

    onVisitHandler,
    onBarcodeHandler,
    onCancelHandler,
    onBookingHandler,
    onChangePaymentTypeHandler,
  }
}

import { CustomCard } from '@components/custom-card/custom-card.component'
import { Redirect } from 'react-router-dom'

import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { genHomePagePath, genSchedulePagePath } from '../../format/path.format'
import { isDef } from '../../types/lang.types'
import { ScheduleGroupPageBackward } from './schedule-group-page-backward/schedule-group-page-backward.component'
import { ScheduleGroupPageEdit } from './schedule-group-page-edit/schedule-group-page-edit.component'
import { ScheduleGroupPageGuests } from './schedule-group-page-guests/schedule-group-page-guests.component'
import { ScheduleGroupPageHeader } from './schedule-group-page-header/schedule-group-page-header.component'
import { ScheduleGroupPageMenu } from './schedule-group-page-menu/schedule-group-page-menu.component'
import { ScheduleGroupPageRecord } from './schedule-group-page-record/schedule-group-page-record-table.component'
import { useScheduleGroupPage } from './schedule-group-page.hook'
import { ScheduleGroupPageSection } from './schedule-group-page.types'

export const ScheduleGroupPage = () => {
  const { studioId, selectedStudioId, section, isLoaded, isLoading } = useScheduleGroupPage()

  if (!isDef(studioId)) {
    if (isDef(selectedStudioId)) {
      return <Redirect to={genSchedulePagePath(selectedStudioId)} />
    }

    return <Redirect to={genHomePagePath()} />
  }

  if (!isLoaded && isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <ScheduleGroupPageBackward />
      <ScheduleGroupPageHeader />
      <ScheduleGroupPageMenu />
      {section === ScheduleGroupPageSection.OVERVIEW && (
        <CustomCard>
          <ScheduleGroupPageGuests />
        </CustomCard>
      )}
      {section === ScheduleGroupPageSection.EDIT && (
        <CustomCard>
          <ScheduleGroupPageEdit />
        </CustomCard>
      )}
      {section === ScheduleGroupPageSection.LONG_RECORD && (
        <CustomCard>
          <ScheduleGroupPageRecord />
        </CustomCard>
      )}
    </>
  )
}

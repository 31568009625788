import { put, takeLatest } from 'redux-saga/effects'
import { callApi } from '@utils/sagas.utils'
import { api } from '@api/api'

import { employeesScheduleEditActions } from './employees-schedule-edit.slice'

export function* fetchById(action: ReturnType<typeof employeesScheduleEditActions.fetchById>) {
  try {
    const { scheduleId, successCallback } = action.payload
    const schedule: Awaited<ReturnType<typeof api.employeesScheduleService.fetchById>> = yield callApi(
      api.employeesScheduleService.fetchById,
      scheduleId
    )

    yield put(employeesScheduleEditActions.fetchByIdSuccess({ schedule: schedule.data }))
    if (successCallback) successCallback()
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleEditActions.fetchByIdError(new Error()))
  }
}

// TODO: Does not work
export function* edit(action: ReturnType<typeof employeesScheduleEditActions.edit>) {
  try {
    const { id, schedule, successCallback } = action.payload
    yield callApi(api.employeesScheduleService.edit, id, schedule)

    yield put(employeesScheduleEditActions.editSuccess())
    if (successCallback) successCallback()
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleEditActions.editError(new Error()))
  }
}

export function* employeesScheduleEditSagas() {
  yield takeLatest(employeesScheduleEditActions.fetchById, fetchById)
  yield takeLatest(employeesScheduleEditActions.edit, edit)
}

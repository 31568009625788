import * as React from 'react'
import { Select, Typography } from 'antd'

import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { ClientsBookingsTableActive } from '../../../components/clients/clients-bookings-tables/clients-bookings-table-active/clients-bookings-table-active.component'
import { isDef } from '../../../types/lang.types'
import { useClientsEditPageBookingsActive } from './clients-edit-page-bookings-active.hook'
import { CustomCard } from '../../../components/custom-card/custom-card.component'
import './clients-edit-page-bookings-active.styles.less'

export const ClientsEditPageBookingsActive: React.FC = () => {
  const {
    data,
    pagination,
    studiosOptions,
    isLoading,
    isLoaded,
    onChangePageHandler,
    onChangePageSizeHandler,
    onVisitHandler,
    onBarcodeHandler,
    onCancelHandler,
    onBookingHandler,
    onStudioChangeHandler,
    onChangePaymentTypeHandler,
  } = useClientsEditPageBookingsActive()

  if (!isLoaded && isLoading) {
    return <PageLoader />
  }

  return (
    <CustomCard>
      <Typography.Title className="clients-edit-booking-active__title" level={4}>
        Записи клиента
      </Typography.Title>

      <div className="clients-edit-booking-active__select-wrapper">
        <Select
          className="clients-edit-booking-active__select"
          placeholder="Студии"
          options={studiosOptions}
          onChange={onStudioChangeHandler}
        />
      </div>

      {isDef(data) ? (
        <ClientsBookingsTableActive
          data={data}
          pagination={pagination}
          onChangePage={onChangePageHandler}
          onChangePageSize={onChangePageSizeHandler}
          onVisit={onVisitHandler}
          onBarcode={onBarcodeHandler}
          onCancel={onCancelHandler}
          onBooking={onBookingHandler}
          onChangePaymentType={onChangePaymentTypeHandler}
        />
      ) : (
        <PageEmpty description="Активные занятия не найдены" />
      )}
    </CustomCard>
  )
}

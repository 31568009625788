import { ColumnsType } from 'antd/es/table'

import { TableCellTag } from '../../../../components/table-cells/table-cell-tag/table-cell-tag.component'
import { TableCellText } from '../../../table-cells/table-cell-text/table-cell-text.component'
import { ClientsBookingsTableBaseDataItem } from './clients-bookings-table-base.types'
import { TableCellDateTimeV2 } from '../../../../components/table-cells/table-cell-date-time-v2/table-cell-date-time-v2.component'
import { TableCellTagEvents } from '../../../table-cells/table-cell-tag/table-cell-tag.types'

export type ClientsBookingsTableEvents = TableCellTagEvents

export function genClientsBookingsTableBaseColumns(
  events: ClientsBookingsTableEvents
): ColumnsType<ClientsBookingsTableBaseDataItem> {
  const { onChangePaymentType } = events
  return [
    {
      title: 'Дата',
      width: 140,
      key: 'date-time-exercise',
      render: (_, booking) => (
        <TableCellDateTimeV2
          createdDate={booking.createDate}
          isShort
          duration={booking.exerciseDuration}
          date={booking.exerciseDate}
          createdBy={booking.createdBy}
          format="HH:mm"
        />
      ),
    },
    {
      title: 'Направление и место',
      dataIndex: 'direction',
      key: 'direction',
      render: (_, booking) => (
        <div>
          <div>
            <TableCellText text={booking.exerciseDirection.title} />
          </div>
          <div
            style={{
              color: ' rgba(0, 0, 0, 0.65)',
              fontSize: 12,
            }}
          >
            {booking.studio.title}
          </div>
        </div>
      ),
    },
    {
      title: 'Исполнитель',
      dataIndex: 'trainers',
      key: 'trainers',
      render: (_, booking) => (
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.65)',
          }}
        >
          {booking.trainers.map(item => item.title).join(',')}
        </div>
      ),
    },
    {
      title: 'Тип записи',
      dataIndex: 'exerciseType',
      key: 'exerciseType',
      width: 220,
      render: (_, booking) => (
        <div>
          <span
            style={{
              display: 'inline-flex',
              padding: '1px 8px',
              border: '1px solid rgba(217, 217, 217, 1)',
              borderRadius: 5,
              fontSize: 12,
              justifyContent: 'center',
            }}
          >
            {booking.exerciseType?.name}
          </span>
        </div>
      ),
    },
    {
      title: 'Метод записи',
      dataIndex: 'paymentType',
      key: 'paymentType',
      width: 170,
      render: (_, booking) => {
        return (
          <TableCellTag
            bookingId={booking.id}
            clientId={booking.client.id}
            paymentType={booking.paymentType}
            phone={booking.client.phone}
            exerciseId={booking.exerciseId}
            exerciseDate={booking.exerciseDate}
            transactionId={booking.transactionId}
            onChangePaymentType={onChangePaymentType}
          />
        )
      },
    },
  ]
}

import * as React from 'react'

import { ClientsAutocomplete } from '../../components/clients/clients-autocomplete/clients-autocomplete.component'
import { useClientsAutocomplete } from './clients-autocomplete.hook'
import { ClientsAutocompleteProps } from './clients-autocomplete.types'

// Clients === Guests
export const ClientsAutocompleteContainer = <Form,>(props: ClientsAutocompleteProps<Form>) => {
  const { className, size, placeholder, suffix } = props
  const { form, name = 'phone', disabled, defaultValue } = props
  const { transactionsForm = false } = props
  const { onChange, setClientId } = props

  const { value, options, loading, onChangeHandler, onBlurHandler, onFocusHandler, onSearchHandler, onSelectHandler } =
    useClientsAutocomplete({
      name,
      form,
      transactionsForm,
      onChange,
      setClientId,
    })

  return (
    <ClientsAutocomplete
      className={className}
      value={value}
      options={options}
      defaultValue={defaultValue}
      loading={loading}
      disabled={disabled}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      onInputChange={onSearchHandler}
      onSelect={onSelectHandler}
      size={size ?? 'middle'}
      placeholder={placeholder}
      suffix={suffix}
    />
  )
}

import { ExercisesTypesApi } from '@api/types/exercises-types-api.types'
import { Tag } from 'antd'

export const TypeTagFormat = ({ typeFormat }: { typeFormat: ExercisesTypesApi.ExerciseTypeFormat }) => {
  let color = ''
  let text = ''

  switch (typeFormat) {
    case 'GROUP':
      color = ''
      text = 'Групповая запись'
      break
    case 'PERSONAL':
      color = 'cyan'
      text = 'Персональная услуга'
      break
    default:
      color = 'red'
      text = 'Не определенный тип'
      break
  }

  return <Tag color={color}>{text}</Tag>
}

import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '@constants/local-storage'
import { Button, Dropdown, Flex, MenuProps, Tag, Tooltip, Typography } from 'antd'
import clsx from 'clsx'
import dayjs from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { DefaultOptionType } from 'antd/lib/select'

import { PaymentType } from '../../../api/types/api.types'
import { DEFAULT_EMPTY_SYMBOL, formatPaymentType } from '../../../format/text.format'
import { Nullable, isDef, isDefAndNotEmpty } from '../../../types/lang.types'
import { TableCellTagProps } from './table-cell-tag.types'
import './table-cell-tag.styles.less'
import { api } from '../../../api/api'
import { genPaymentTypesOptions } from '../../../utils/payment.utils'

function formatTrial(isTrial?: boolean): string {
  if (isDef(isTrial) && isTrial) return ' Пробное'
  else return ''
}

export const TableCellTag = (props: TableCellTagProps) => {
  // Props
  const { bookingId, clientId, exerciseId, phone, paymentType, isTrial, exerciseDate, transactionId } = props
  const { onChangePaymentType } = props

  const history = useHistory()
  const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)

  const tagText = formatPaymentType(paymentType) + formatTrial(isTrial)

  let currentColor = ''
  switch (paymentType) {
    case 'SUBSCRIPTION':
      currentColor = 'magenta'
      break
    case 'ONE_TIME':
      currentColor = 'geekblue'
      break
    case 'ON_PLACE':
      currentColor = 'orange'
      break
    case 'RESERVED':
      currentColor = 'orange'
      break
    case 'TRIAL':
      currentColor = 'green'
      break
    default:
      currentColor = 'magenta'
  }

  const [dropdownMenuOpen, setDropdownMenuOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [paymentTypes, setPaymentTypes] = React.useState<DefaultOptionType[]>([])

  const handleCreateTransaction = () => {
    history.push({
      pathname: `/transactions/${studioId}/create`,
      state: { phone, exerciseId, clientId },
    })
  }

  const handleChangePaymentType = (paymentType: Nullable<PaymentType>) => {
    if (exerciseId && paymentType) {
      onChangePaymentType(exerciseId, paymentType, bookingId)
    }
  }

  const handleButtonCreateTransaction = React.useCallback(async () => {
    if (exerciseId) {
      setIsLoading(true)

      const phoneNumber = phone?.replace(/\D/g, '')

      await api.exercisesService
        .fetchPaymentTypes(exerciseId, phoneNumber)
        .then(res => {
          const options = genPaymentTypesOptions(res.data?.filter(type => type !== PaymentType.ON_PLACE))
          setPaymentTypes(options)
          if (isDefAndNotEmpty(options)) {
            setDropdownMenuOpen(true)
          } else {
            handleCreateTransaction()
          }
        })
        .finally(() => setIsLoading(false))
    }
  }, [exerciseId, phone])

  const paymentTypesItems: MenuProps['items'] = React.useMemo(() => {
    return [
      {
        key: '1',
        label: (
          <div
            onClick={() => {
              setDropdownMenuOpen(false)
              handleCreateTransaction()
            }}
          >
            Создать транзакцию
          </div>
        ),
      },
      ...(isDefAndNotEmpty(paymentTypes)
        ? [
            {
              key: '2',
              type: 'group',
              label: 'В наличии у клиента',
              children: paymentTypes.map((option, index) => ({
                key: `1-${index}`,
                label: (
                  <div
                    onClick={() => {
                      setDropdownMenuOpen(false)
                      handleChangePaymentType(option.value as Nullable<PaymentType>)
                    }}
                  >
                    {option.label}
                  </div>
                ),
              })),
            },
          ]
        : []),
    ]
  }, [paymentTypes])

  if (isDef(tagText)) {
    return (
      <Flex vertical gap="small" className="table-cell-tag">
        <Tag
          className={clsx('table-cell-tag__tag', {
            'table-cell-tag__tag--waiting': paymentType === 'WAITING',
          })}
          color={currentColor}
        >
          {paymentType === 'SUBSCRIPTION' ? (
            <Tooltip title={exerciseDate ? `Дата покупки абонемента: ${dayjs(exerciseDate).format('DD.MM.YYYY')}` : ''}>
              <Typography.Text style={{ color: currentColor }}>{tagText}</Typography.Text>
            </Tooltip>
          ) : (
            <Typography.Text
              onClick={() => {
                transactionId &&
                  history.push({
                    pathname: `/clients/${clientId}/purchases`,
                    state: {
                      transactionId,
                    },
                  })
              }}
              style={{
                color: currentColor,
                ...(transactionId
                  ? {
                      cursor: 'pointer',
                    }
                  : {}),
              }}
            >
              {tagText}
            </Typography.Text>
          )}
        </Tag>
        {paymentType === 'ON_PLACE' && (
          <Dropdown
            open={isLoading ? false : dropdownMenuOpen}
            onOpenChange={(nextOpen, info) => {
              if (info.source === 'trigger' || nextOpen) {
                setDropdownMenuOpen(nextOpen)
              }
            }}
            menu={{ items: paymentTypesItems }}
            placement="bottom"
            arrow
            trigger={['click']}
          >
            <Button
              size="small"
              type="dashed"
              className="table-cell-tag__button"
              loading={isLoading}
              onClick={handleButtonCreateTransaction}
            >
              Создать оплату
            </Button>
          </Dropdown>
        )}
      </Flex>
    )
  }

  return <Typography.Text>{DEFAULT_EMPTY_SYMBOL}</Typography.Text>
}

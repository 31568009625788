import { Tab } from 'rc-tabs/lib/interface'

import { formatScheduleGroupPageSection } from '../../../format/text.format'
import { getStrEnumValues } from '../../../utils/enum.utils'
import { ScheduleGroupPageSection } from '../schedule-group-page.types'

export function genScheduleGroupPageMenuTabs({
  linkedLength,
  scheduleFormat,
}: {
  linkedLength: number
  scheduleFormat: 'PERSONAL' | 'GROUP'
}): Tab[] {
  // Get all tab sections from the enum
  const allTabs = getStrEnumValues<ScheduleGroupPageSection>(ScheduleGroupPageSection).map(
    (section: ScheduleGroupPageSection): Tab => ({
      key: section,
      label: formatScheduleGroupPageSection(section),
    })
  )

  // Check if we need to show only OVERVIEW and EDIT tabs
  if ((scheduleFormat === 'PERSONAL' && linkedLength === 0) || scheduleFormat === 'GROUP') {
    return allTabs.filter(
      tab => tab.key === ScheduleGroupPageSection.OVERVIEW || tab.key === ScheduleGroupPageSection.EDIT
    )
  }

  // In all other cases, return all tabs
  return allTabs
}

import { Tag, Typography } from 'antd'
import { ColoredDictionaryItem } from '@api/types/api.types'

import { isDef } from '../../../types/lang.types'
import { DEFAULT_EMPTY_SYMBOL } from '../../../format/text.format'

interface Props {
  rooms: ColoredDictionaryItem[]
}

export const TableCellStudioRoom = ({ rooms }: Props) => {
  if (isDef(rooms)) {
    return (
      <>
        {rooms.map(room => (
          <Tag key={room.id} color={room.color}>
            {room.name}
          </Tag>
        ))}
      </>
    )
  }

  return <Typography.Text>{DEFAULT_EMPTY_SYMBOL}</Typography.Text>
}

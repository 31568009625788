import * as React from 'react'
import { Col, Row } from 'antd'

import { StudiosTable } from '../../../components/studios/studios-table/studios-table.component'
import { useStudiosPageTable } from './studios-page-table.hook'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { CustomCard } from '../../../components/custom-card/custom-card.component'

export const StudiosPageTableComponent: React.FC = () => {
  const {
    studios,
    isLoading,
    onChangePageHandler,
    onChangePageSizeHandler,
    onEditHandler,
    onRemoveHandler,
    pagination,
  } = useStudiosPageTable()

  if (isLoading) {
    return <PageLoader />
  }
  return (
    <CustomCard>
      <Row>
        <Col span={24}>
          <StudiosTable
            pagination={pagination}
            data={studios || []}
            onChangePage={onChangePageHandler}
            onChangePageSize={onChangePageSizeHandler}
            onEdit={onEditHandler}
            onRemove={onRemoveHandler}
          />
        </Col>
      </Row>
    </CustomCard>
  )
}

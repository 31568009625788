import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import { formatTimeLimitation } from '../../../format/text.format'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { TableCellDateTime } from '../../table-cells/table-cell-date-time/table-cell-date-time.component'
import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { ClientsBookingsTableHistoryStatus } from '../clients-bookings-tables/clients-bookings-table-history/clients-bookings-table-history-status/clients-bookings-table-history-status.component'
import { ClientsTransactionsTablePayment } from '../clients-transactions-table/clients-transactions-table-payment/clients-transactions-table-payment.component'
import { ClientsSubscriptionsTableActions } from './clients-subscriptions-table-actions/clients-subscriptions-table-actions.component'
import { ClientsSubscriptionsTableActionsEvents } from './clients-subscriptions-table-actions/clients-subscriptions-table-actions.types'
import { ClientsSubscriptionsTableActivation } from './clients-subscriptions-table-activation/clients-subscriptions-table-activation.component'
import { ClientsSubscriptionsTableBookingsExerciseTime } from './clients-subscriptions-table-bookings-exercise-time/clients-subscriptions-table-bookings-exercise-time.component'
import { ClientsSubscriptionsTableExercisesTypes } from './clients-subscriptions-table-exercises-types/clients-subscriptions-table-exercises-types.component'
import { ClientsSubscriptionsTableExpiration } from './clients-subscriptions-table-expiration/clients-subscriptions-table-expiration.component'
import { ClientsSubscriptionsTableId } from './clients-subscriptions-table-id/clients-subscriptions-table-id.component'
import { ClientsSubscriptionsTablePurchase } from './clients-subscriptions-table-purchase/clients-subscriptions-table-purchase.component'
import { ClientsSubscriptionsTableStatus } from './clients-subscriptions-table-status/clients-subscriptions-table-status.component'
import { ClientsSubscriptionsTableStudios } from './clients-subscriptions-table-studios/clients-subscriptions-table-studios.component'
import { ClientsSubscriptionsTableVisits } from './clients-subscriptions-table-visits/clients-subscriptions-table-visits.component'
import {
  ClientsSubscriptionsTableDataItem,
  ClientsSubscriptionsTableDataItemBooking,
} from './clients-subscriptions-table.types'

interface genClientsSubscriptionsTableColumnsEvents extends ClientsSubscriptionsTableActionsEvents {
  onExpirationDate: (expirationDate: string, subscriptionId: string) => void
  onBooking: (studioId: string, scheduleId: string) => void
}

export function genClientsSubscriptionsTableColumns(
  events: genClientsSubscriptionsTableColumnsEvents
): ColumnsType<ClientsSubscriptionsTableDataItem> {
  const { onBarcode, onPause, onResume, onRefund, onExpirationDate, onBooking } = events

  return [
    {
      title: 'Название абонемента',
      key: 'name',
      render: (_, subscription) => {
        const { name } = subscription

        return <ClientsSubscriptionsTableId id={name} />
      },
    },
    {
      title: 'Действие',
      key: 'visits',
      render: (_, subscription) => {
        const { visitsLeft, visitsTotal, status, variant, minutes, availableMinutes, duration } = subscription

        return (
          <ClientsSubscriptionsTableVisits
            availableMinutes={availableMinutes}
            minutes={minutes}
            duration={duration}
            variant={variant}
            left={visitsLeft}
            total={visitsTotal}
            status={status}
          />
        )
      },
    },
    {
      title: 'Дата покупки',
      key: 'purchaseDate',
      render: (_, subscription) => {
        const { purchaseDate } = subscription
        const { id } = subscription

        return <ClientsSubscriptionsTablePurchase purchaseDate={purchaseDate} id={id} />
      },
    },
    {
      title: 'Метод оплаты',
      key: 'paymentMethod',

      render: (_, subscription) => <ClientsTransactionsTablePayment value={subscription.paymentMethod} />,
    },
    {
      title: 'Дата активации',
      key: 'activation',
      render: (_, subscription) => {
        const { activationDate, autoActivationDate, status, activationBooking } = subscription

        return (
          <ClientsSubscriptionsTableActivation
            activationDate={activationDate}
            autoActivationDate={autoActivationDate}
            status={status}
            activationBooking={activationBooking}
            onBooking={onBooking}
          />
        )
      },
    },
    {
      title: 'Срок действия',
      key: 'expiration',
      render: (_, subscription) => {
        const { id, autoActivationDate, expirationDate, status } = subscription

        return (
          <ClientsSubscriptionsTableExpiration
            subscriptionId={id}
            autoActivationDate={autoActivationDate}
            expirationDate={expirationDate}
            status={status}
            onExpirationDate={onExpirationDate}
          />
        )
      },
    },
    {
      title: 'Студии',
      key: 'studios',
      render: (_, subscription) => {
        const { studios, hasStudioLimitation } = subscription

        return <ClientsSubscriptionsTableStudios studios={studios} hasStudioLimitation={hasStudioLimitation} />
      },
    },
    {
      title: 'Время посещения',
      key: 'timeLimitation',
      render: (_, subscription) => <TableCellText text={formatTimeLimitation(subscription.timeLimitation)} />,
    },
    {
      title: 'Типы записей',
      key: 'exercisesTypesLimitation',
      render: (_, subscription) => {
        const { availableTypes, hasTypeLimitation } = subscription
        return (
          <ClientsSubscriptionsTableExercisesTypes
            availableTypes={availableTypes}
            hasTypeLimitation={hasTypeLimitation}
          />
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_, subscription) => <ClientsSubscriptionsTableStatus value={subscription.status} />,
    },
    {
      title: 'Операции',
      key: 'actions',
      render: (_, subscription) => {
        const { id, status } = subscription

        return (
          <ClientsSubscriptionsTableActions
            subscriptionId={id}
            status={status}
            onBarcode={onBarcode}
            onPause={onPause}
            onResume={onResume}
            onRefund={onRefund}
          />
        )
      },
    },
  ]
}

export function genClientSubscriptionBookingsTableColumns(
  onBooking: (studioId: string, scheduleId: string) => void
): ColumnsType<ClientsSubscriptionsTableDataItemBooking> {
  return [
    {
      title: 'Время занятия',
      key: 'date-time-exercise',
      render: (_, booking) => {
        const { exerciseId, studio } = booking

        return (
          <ClientsSubscriptionsTableBookingsExerciseTime
            date={booking.exerciseDate}
            exerciseId={exerciseId}
            studioId={studio.id}
            onBooking={onBooking}
          />
        )
      },
    },
    {
      title: 'Время бронирования',
      key: 'date-time',
      render: (_, booking) => <TableCellDateTime date={booking.createDate} format="HH:mm" />,
    },
    {
      title: 'Групповые направления',
      dataIndex: 'direction',
      key: 'direction',
      render: (_, booking) => <TableCellText text={booking.exerciseDirection.title} />,
    },
    {
      title: 'Студия',
      dataIndex: 'studio',
      key: 'studio',
      render: (_, booking) => <TableCellText text={booking.studio.title} />,
    },
    {
      title: 'Статус',
      key: 'status',
      render: (_, booking) => {
        const { visitConfirmed, canceled } = booking

        return <ClientsBookingsTableHistoryStatus isCanceled={canceled} isVisitConfirmed={visitConfirmed} />
      },
    },
  ]
}

export const genClientSubscriptionsTableExpandedRowRender = (
  record: ClientsSubscriptionsTableDataItem,
  index: number,
  indent: number,
  expanded: boolean,
  onBooking: (studioId: string, scheduleId: string) => void
) => {
  const columns = genClientSubscriptionBookingsTableColumns(onBooking)

  return <Table dataSource={record.bookings} columns={columns} pagination={false} loading={false} />
}

export const genClientSubscriptionsTableRowExpandable = (subscription: ClientsSubscriptionsTableDataItem): boolean => {
  return isDefAndNotEmpty(subscription.bookings)
}

import { push } from 'connected-react-router'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { genSchedulePagePath } from '../../../../format/path.format'
import { genExercisesGroupEditUpdateDTO } from '../../../../mapping/exercises.mapping'
import { genDateOfStartFromScheduleGroupPageExercise } from '../../../../pages/schedule-group-page/schedule-group-page-edit/schedule-group-page-edit.utils'
import { isDef } from '../../../../types/lang.types'
import { callApi } from '../../../../utils/sagas.utils'
import { getCurrentStudioId } from '../../../common/layout/layout.selectors'
import { reFetchExercise } from '../schedule-group-page-list/schedule-group-page-list.sagas'
import { genScheduleGroupPageExercise } from '../schedule-group-page-list/schedule-group-page-list.selectors'
import { scheduleGroupPageEditActions } from './schedule-group-page-edit.slice'

export function* fetchPageData(action: ReturnType<typeof scheduleGroupPageEditActions.fetchPageData>) {
  try {
    const [trainersResponse, studiosRoomsResponse, directionsResponse, exercisesTypesResponse]: [
      Awaited<ReturnType<typeof api.trainersService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosRoomsService.fetchAll>>,
      Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchAll>>,
      Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>>
    ] = yield all([
      callApi(api.trainersService.fetchAll, { size: 300 }),
      callApi(api.studiosRoomsService.fetchAll, action.payload),
      callApi(api.exercisesDirectionsService.fetchAll, { size: 200 }),
      callApi(api.exercisesTypesService.fetchAll, { size: 200 }),
    ])

    yield put(
      scheduleGroupPageEditActions.fetchPageDataSuccess({
        trainers: trainersResponse.data,
        studiosRooms: studiosRoomsResponse.data.content,
        directions: directionsResponse.data,
        exercisesTypes: exercisesTypesResponse.data,
      })
    )
  } catch (e) {
    yield put(scheduleGroupPageEditActions.fetchPageDataError(new Error()))
  }
}

export function* editExercise(action: ReturnType<typeof scheduleGroupPageEditActions.editExercise>) {
  try {
    const { exerciseId, exercisesGroupEditFormValuesDTO } = action.payload

    const scheduleGroupPageExercise: ReturnType<typeof genScheduleGroupPageExercise> = yield select(
      genScheduleGroupPageExercise
    )

    if (isDef(scheduleGroupPageExercise)) {
      const exercisesUpdateDTO = genExercisesGroupEditUpdateDTO(exercisesGroupEditFormValuesDTO)
      if (isDef(exercisesUpdateDTO)) {
        yield callApi(api.exercisesService.update, exerciseId, exercisesUpdateDTO)
        yield put(scheduleGroupPageEditActions.editExerciseSuccess())

        yield call(reFetchExercise)
      } else {
        yield put(scheduleGroupPageEditActions.editExerciseError(new Error()))
      }
    } else {
      yield put(scheduleGroupPageEditActions.editExerciseError(new Error()))
    }
  } catch (e) {
    yield put(scheduleGroupPageEditActions.editExerciseError(new Error()))
  }
}

export function* removeExercise(action: ReturnType<typeof scheduleGroupPageEditActions.removeExercise>) {
  try {
    const studioId: ReturnType<typeof getCurrentStudioId> = yield select(getCurrentStudioId)
    const scheduleGroupPageExercise: ReturnType<typeof genScheduleGroupPageExercise> = yield select(
      genScheduleGroupPageExercise
    )
    if (isDef(scheduleGroupPageExercise) && isDef(studioId)) {
      const scheduleDate = genDateOfStartFromScheduleGroupPageExercise(scheduleGroupPageExercise)
      yield callApi(api.exercisesService.cancel, action.payload)
      yield put(scheduleGroupPageEditActions.removeExerciseSuccess())
      yield put(push(genSchedulePagePath(studioId, { date: scheduleDate })))
    } else {
      yield put(scheduleGroupPageEditActions.editExerciseError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(scheduleGroupPageEditActions.removeExerciseError(new Error()))
  }
}

export function* scheduleGroupPageEditSagas() {
  yield takeLatest(scheduleGroupPageEditActions.fetchPageData.type, fetchPageData)
  yield takeLatest(scheduleGroupPageEditActions.editExercise.type, editExercise)
  yield takeLatest(scheduleGroupPageEditActions.removeExercise.type, removeExercise)
}

import { FC } from 'react'

import { ExercisesModal } from '../../../components/exercises/exercises-modal/exercises-modal.component'
import { useSchedulePageModalCreate } from './schedule-page-modal-create.hook'
import { ISchedulePageModalCreateProps } from './schedule-page-modal-create.types'

export const SchedulePageModalCreate: FC<ISchedulePageModalCreateProps> = ({
  studioId,
  studioOffset,
  studioRoomId,
  date,
  timeFrom,
  timeTo,
  isFrozenTime,
  timetableType,
}) => {
  const { form, isLoading, exercisesTypes, trainers, onSaveHandler, onCancelHandler, currentRoom, saveStatus } =
    useSchedulePageModalCreate(studioId, studioRoomId, date, timeFrom, timeTo, timetableType)

  return (
    <ExercisesModal
      title="Создание записи"
      form={form}
      studioOffset={studioOffset}
      exercisesTypes={exercisesTypes}
      trainers={trainers}
      currentRoom={currentRoom}
      loading={isLoading}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
      saveStatus={saveStatus}
      isFrozenTime={isFrozenTime}
    />
  )
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { NEmployeesScheduleEdit } from './employees-schedule-edit.types'

const initialState: NEmployeesScheduleEdit.IState = {
  schedule: null,
  isLoading: false,
  error: null,
}

export const { actions: employeesScheduleEditActions, reducer: employeesScheduleEditReducer } = createSlice({
  name: '@@employees-schedule-edit',
  initialState,
  reducers: {
    // Fetch by id
    fetchById: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      _: PayloadAction<NEmployeesScheduleEdit.IFetchByIdPayload>
    ) => {
      state.isLoading = true
    },
    fetchByIdSuccess: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      action: PayloadAction<NEmployeesScheduleEdit.IFetchByIdSuccessPayload>
    ) => {
      state.isLoading = false
      state.schedule = action.payload.schedule
    },
    fetchByIdError: (state: Draft<NEmployeesScheduleEdit.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    reset: (state: Draft<NEmployeesScheduleEdit.IState>) => {
      state.schedule = null
    },

    // Edit
    edit: (state: Draft<NEmployeesScheduleEdit.IState>, _: PayloadAction<NEmployeesScheduleEdit.IEditPayload>) => {
      state.isLoading = true
    },
    editSuccess: (state: Draft<NEmployeesScheduleEdit.IState>) => {
      state.isLoading = false
    },
    editError: (state: Draft<NEmployeesScheduleEdit.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

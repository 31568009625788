import * as React from 'react'
import { Input, InputRef } from 'antd'

import { AutocompleteInputProps } from './autocomplete-input.types'
import { formatSearchPhoneNumber } from '../../../../format/phone.format'

const AutocompleteInputInternal = (props: AutocompleteInputProps, ref: React.Ref<InputRef>) => {
  const { value, placeholder, disabled, onChange, onBlur, onFocus, size, suffix, onClick } = props

  return (
    <Input
      ref={ref}
      value={formatSearchPhoneNumber(value)}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      size={size}
      onClick={onClick}
      suffix={suffix}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

export const AutocompleteInput = React.forwardRef(AutocompleteInputInternal)

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { LOCAL_STORAGE_CURRENT_TIMETABLE } from '@constants/local-storage'

import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import { StudiosApi } from '../../../../api/types/studios-api.types'
import { StudiosRoomsApi } from '../../../../api/types/studios-rooms-api.types'
import { SchedulePageParams } from '../../../../pages/schedule-page/schedule-page.types'
import { Nullable } from '../../../../types/lang.types'
import { SchedulePageListFetchPageDataSuccessPayload } from './schedule-page-list.types'
import { BreaksApi } from '../../../../api/types/breaks.types'

export interface SchedulePageListState {
  exercises: Nullable<ExercisesApi.Exercise[]>
  breaks: Nullable<BreaksApi.Break[]>
  studio: Nullable<StudiosApi.Studio>
  studiosRooms: Nullable<StudiosRoomsApi.StudioRoom[]>
  isLoading: boolean
  isLoaded: boolean
  isCanceling: boolean
  error: Nullable<Error>
  currentTimetable: 'daily' | 'weekly'
}

const defaultCurrentTimetable = (localStorage.getItem(LOCAL_STORAGE_CURRENT_TIMETABLE) as 'weekly') ?? 'daily'
const initialState: SchedulePageListState = {
  exercises: null,
  breaks: null,
  studio: null,
  studiosRooms: null,
  isLoading: false,
  isLoaded: false,
  isCanceling: false,
  error: null,
  currentTimetable: defaultCurrentTimetable,
}

export const { actions: schedulePageListActions, reducer: schedulePageListReducer } = createSlice({
  name: '@@schedule-page-list',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<SchedulePageListState>, _: PayloadAction<SchedulePageParams>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<SchedulePageListState>,
      action: PayloadAction<SchedulePageListFetchPageDataSuccessPayload>
    ) => {
      const { exercises, breaks, studio, studiosRooms } = action.payload
      state.exercises = exercises
      state.breaks = breaks
      state.studio = studio
      state.studiosRooms = studiosRooms
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<SchedulePageListState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    cancelExercise: (state: Draft<SchedulePageListState>, _: PayloadAction<string>) => {
      state.isCanceling = true
    },
    cancelExerciseSuccess: (state: Draft<SchedulePageListState>) => {
      state.isCanceling = false
    },
    cancelExerciseError: (state: Draft<SchedulePageListState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isCanceling = false
    },
    cancelBreak: (state: Draft<SchedulePageListState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    cancelBreakSuccess: (state: Draft<SchedulePageListState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    cancelBreakError: (state: Draft<SchedulePageListState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    updateCurrentTimetable: (state: Draft<SchedulePageListState>) => {
      const newCurrentTimetable = state.currentTimetable === 'daily' ? 'weekly' : 'daily'
      state.currentTimetable = newCurrentTimetable
      localStorage.setItem(LOCAL_STORAGE_CURRENT_TIMETABLE, newCurrentTimetable)
    },
    reset: () => {
      const currentTimetable = (localStorage.getItem(LOCAL_STORAGE_CURRENT_TIMETABLE) as 'weekly') ?? 'daily'
      const newInitialState = { ...initialState, currentTimetable }
      return newInitialState
    },

    filterByTags: (
      state: Draft<SchedulePageListState>,
      action: PayloadAction<{ tags: Array<{ type: string; value: string }>; timetableType: 'weekly' | 'daily' }>
    ) => {
      const { tags, timetableType } = action.payload

      let trainers: string[] = []
      let directions: string[] = []
      let rooms: string[] = []
      let record: string[] = []
      let paymentTypes: string[] = []

      // Create collections for filtration
      tags.forEach(item => {
        switch (item.type) {
          case 'trainers':
            return trainers.push(item.value)
          case 'directions':
            return directions.push(item.value)
          case 'rooms':
            return rooms.push(item.value)
          case 'records':
            return record.push(item.value)
          case 'paymentTypes':
            return paymentTypes.push(item.value)
        }
      })

      if (!state.exercises) return
      state.exercises = state.exercises.map(exercise => {
        // If collection is empty - return all exercises with isFilled = true
        if ([trainers, directions, rooms, record, paymentTypes].every(arr => arr.length === 0)) {
          return { ...exercise, isFilled: false }
        }

        // Check if exercise has trainer, direction, room or record
        const hasTrainer = (exercise.trainers ?? []).some((trainer: any) => trainers.includes(trainer.id))
        const hasDirection = directions.some((direction: string) => Number(direction) === exercise?.direction?.id)
        const hasRoom = rooms.some((room: string) => room === exercise.room.id)
        const hasRecord = record.some((record: string) => Number(record) === exercise?.type?.id)
        const hasPaymentType = paymentTypes.some(
          (paymentType: string) => paymentType === exercise?.masterServiceBooking?.paymentType
        )

        // Filter by timetable
        if (timetableType === 'weekly') {
          // Show all exercises for current room
          const isHidden = !hasRoom
          if ([trainers, directions, record, paymentTypes].every(arr => arr.length === 0)) {
            return { ...exercise, isFilled: false, isHidden }
          }

          const isNotFilled = hasTrainer || hasDirection || hasRecord || hasPaymentType
          return {
            ...exercise,
            isFilled: !isNotFilled,
            isHidden,
          }
        }

        // Filter by daily
        const isNotFilled = hasTrainer || hasDirection || hasRoom || hasRecord || hasPaymentType

        return {
          ...exercise,
          isFilled: !isNotFilled,
          isHidden: false,
        }
      })

      if (!state.breaks) return
      state.breaks = state.breaks.map(timetableBreak => {
        // If collection is empty - return all breaks with isFilled = true
        if ([trainers, directions, rooms, record, paymentTypes].every(arr => arr.length === 0)) {
          return { ...timetableBreak, isFilled: false }
        }

        // Check if breaks has room
        const hasTrainer = false
        const hasDirection = false
        const hasRoom = rooms.some((room: string) => room === timetableBreak.room.id)
        const hasRecord = false
        const hasPaymentType = false

        // Filter by timetable
        if (timetableType === 'weekly') {
          // Show all breaks for current room
          const isHidden = !hasRoom
          if ([trainers, directions, record, paymentTypes].every(arr => arr.length === 0)) {
            return { ...timetableBreak, isFilled: false, isHidden }
          }

          const isNotFilled = hasTrainer || hasDirection || hasRecord || hasPaymentType
          return {
            ...timetableBreak,
            isFilled: !isNotFilled,
            isHidden,
          }
        }

        // Filter by daily
        const isNotFilled = hasTrainer || hasDirection || hasRoom || hasRecord || hasPaymentType

        return {
          ...timetableBreak,
          isFilled: !isNotFilled,
          isHidden: false,
        }
      })
    },
  },
})

import * as React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Table, TablePaginationConfig } from 'antd'

import { ClientsTransactionsTableActionsEvents } from './clients-transactions-table-actions/clients-transactions-table-actions.types'
import { genClientsTransactionsTableColumns } from './clients-transactions-table.utils'
import { ClientsTransactionsTableDataItem } from './clients-transactions-table.types'
import { checkIsServiceType } from '../../../utils/status.utils'
import { Nullable } from '../../../types/lang.types'
import { ClientsBookingsApi } from '../../../api/types/clients-bookings-api.types'
import { TransactionStatus } from '../../../types/transactions.types'

import './clients-transactions-table.styles.less'

interface Props extends ClientsTransactionsTableActionsEvents {
  data: ClientsTransactionsTableDataItem[]
  pagination?: TablePaginationConfig
  loading?: boolean
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
  onRefundSumHandler: (transaction: ClientsTransactionsTableDataItem) => void
  onBooking: (studioId: string, scheduleId: string) => void
  onHoverUsed: (bookingId: string) => void
  isUsedBookingLoading: boolean
  usedBooking: Nullable<ClientsBookingsApi.ClientBooking>
}

export const ClientsTransactionsTable: React.FC<Props> = props => {
  const { data, pagination, loading, isUsedBookingLoading, usedBooking } = props
  const { onBarcode, onChangePage, onChangePageSize, onRefundSumHandler, onBooking, onHoverUsed } = props

  const columns: ColumnsType<ClientsTransactionsTableDataItem> = React.useMemo(
    () =>
      genClientsTransactionsTableColumns(
        { onBarcode, onRefundSumHandler },
        onBooking,
        onHoverUsed,
        isUsedBookingLoading,
        usedBooking
      ),
    [onBarcode, onRefundSumHandler]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  return (
    <Table
      rowKey="id"
      rowClassName={transaction => {
        const isServiceType = checkIsServiceType(transaction.products[0].type)
        const isUsed = transaction.products[0].used
        const isPayed = transaction.status === TransactionStatus.PAID
        return isServiceType && !isUsed && isPayed ? 'clients-transaction-table__row_unused' : ''
      }}
      columns={columns}
      dataSource={data}
      pagination={paginationConfig}
      loading={loading}
    />
  )
}

import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Flex } from 'antd'
import { CopyOutlined, DeleteOutlined, EditOutlined, PauseOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { employeesScheduleActions } from '@store/pages/employees/employees-schedule/employees-schedule.slice'
import { EMPLOYEE_SCHEDULE_EDIT_MODE, EMPLOYEE_SCHEDULE_STATUS } from '@constants/employees/schedule'
import { ModalConfirmDelete } from '@components/modals/modal-confirm-delete/modal-confirm-delete.component'
import { useModal } from '@hooks/use-modal.hook'

import { IActionsCellProps } from './actions-cell.types'

export const ActionsCell: FC<IActionsCellProps> = ({ schedule }) => {
  // SECTION: Hooks
  const dispatch = useDispatch()
  const history = useHistory()

  // SECTION: Actions
  const copy = () =>
    history.push({
      pathname: '/employees/schedule/create',
      state: { schedule, mode: EMPLOYEE_SCHEDULE_EDIT_MODE.COPY },
    })
  const edit = () => history.push(`/employees/schedule/edit/${schedule.id}`)
  const deleteSchedule = () => dispatch(employeesScheduleActions.delete({ scheduleId: schedule.id }))

  // Status update
  const stop = () => dispatch(employeesScheduleActions.stop({ scheduleId: schedule.id }))
  const active = () => dispatch(employeesScheduleActions.active({ scheduleId: schedule.id }))

  // SECTION: Custom hooks
  const [
    isShowModalConfirmDelete,
    handleOpenModalConfirmDelete,
    handleCloseModalConfirmDelete,
    handleOkModalConfirmDelete,
  ] = useModal(deleteSchedule)

  // SECTION: Render
  return (
    <Flex gap="small">
      {schedule.status === EMPLOYEE_SCHEDULE_STATUS.ACTIVE && <Button icon={<PauseOutlined />} onClick={stop} />}

      {[EMPLOYEE_SCHEDULE_STATUS.DRAFT, EMPLOYEE_SCHEDULE_STATUS.PAUSED].some(status => schedule.status === status) && (
        <Button icon={<PlayCircleOutlined />} onClick={active} />
      )}

      {[EMPLOYEE_SCHEDULE_STATUS.DRAFT, EMPLOYEE_SCHEDULE_STATUS.PAUSED, EMPLOYEE_SCHEDULE_STATUS.ACTIVE].some(
        status => schedule.status === status
      ) && <Button icon={<EditOutlined />} onClick={edit} />}

      <Button icon={<CopyOutlined />} onClick={copy} />
      <Button danger icon={<DeleteOutlined />} onClick={handleOpenModalConfirmDelete} />

      <ModalConfirmDelete
        isModalOpen={isShowModalConfirmDelete}
        handleOk={handleOkModalConfirmDelete}
        handleCancel={handleCloseModalConfirmDelete}
      />
    </Flex>
  )
}

import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StudiosRoomsApi } from '../../../api/types/studios-rooms-api.types'
import {
  ExercisesFormValues,
  ExercisesFormValuesDTO,
} from '../../../components/exercises/exercises-form/exercises-form.types'
import { CREATE_EXERCISE_BROADCAST } from '../../../constants/broadcast'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { genStudiosRoomsInternal } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import {
  getSchedulePageModalCreateExercisesTypes,
  getSchedulePageModalCreateIsLoading,
  getSchedulePageModalCreateTrainers,
} from '../../../store/pages/schedule-page/schedule-page-modal-create/schedule-page-modal-create.selectors'
import { schedulePageModalCreateActions } from '../../../store/pages/schedule-page/schedule-page-modal-create/schedule-page-modal-create.slice'
import { isDef, isDefAndNotEmpty } from '../../../types/lang.types'

export function useSchedulePageModalCreate(
  studioId: string,
  studioRoomId: string,
  date: string,
  timeFrom?: string,
  timeTo?: string,
  timetableType?: 'weekly' | 'daily'
) {
  const bc = new BroadcastChannel(CREATE_EXERCISE_BROADCAST)

  const [form] = Form.useForm<ExercisesFormValues>()

  const dispatch = useDispatch()

  const isLoading = useSelector(getSchedulePageModalCreateIsLoading)

  const exercisesTypes = useSelector(getSchedulePageModalCreateExercisesTypes)
  const trainers = useSelector(getSchedulePageModalCreateTrainers)
  const rooms = useSelector(genStudiosRoomsInternal)

  const [room, setRoom] = useState<StudiosRoomsApi.StudioRoom | null | undefined>(null)

  const [saveStatus, setSaveStatus] = useState<'success' | 'error' | 'default'>('default')

  const onSaveHandler = (exercisesFormValuesDTO: ExercisesFormValuesDTO): void => {
    // Create record
    dispatch(
      schedulePageModalCreateActions.createExercise({
        studioRoomId,
        exercisesFormValuesDTO,
        date,
        timetableType,
      })
    )

    // Set up guest
    bc.onmessage = event => {
      const response = JSON.parse(event.data)
      setSaveStatus(response.status)
    }
  }

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  useEffect(() => {
    if (isDefAndNotEmpty(rooms)) {
      setRoom(rooms.find(studioRoom => studioRoom.id === studioRoomId))
    }
  }, [rooms, studioRoomId])

  useEffect(() => {
    dispatch(schedulePageModalCreateActions.fetchDictionaries({ studioId, roomId: studioRoomId }))
  }, [dispatch, studioId])

  useEffect(() => {
    if (isDef(timeFrom)) {
      const formValues = form.getFieldsValue()

      form.setFieldsValue({
        ...formValues,
        time: {
          start: timeFrom,
          end: timeTo,
        },
      })
    }
  }, [form, timeFrom, timeTo])

  useEffect(() => {
    return () => {
      dispatch(schedulePageModalCreateActions.reset())
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      bc.close()
    }
  }, [])

  return {
    form,
    isLoading,
    exercisesTypes,
    trainers,
    currentRoom: room,
    onSaveHandler,
    onCancelHandler,
    saveStatus,
  }
}

import { useDispatch, useSelector } from 'react-redux'
import { FC, useEffect, useState } from 'react'
import { Button, Flex, Table, Tag, Typography } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import { getStudios } from '@store/common/entity/studio/studio.selectors'
import { studioEntityActions } from '@store/common/entity/studio/studio.slice'

import { HourCell } from './ui/hour-cell/hour-cell.component'
import { Nullable } from '../../../../types/lang.types'
import { StudiosCollapse } from './ui/studios-collapses/studios-collapses.component'
import { FIGURE_BUTTON_EVENT_TYPE } from './constants/figure'
import {
  ICurrentStudioSlice,
  IWorkTimeScheduleStudio,
  IWorkTimeScheduleProps,
  IFigurePreset,
} from './work-time-schedule.types'
import { IFigure, IHourCellEvent } from './ui/hour-cell/hour-cell.types'
import { useDefaultValues } from './hooks/useDefaultValues'
import { useInitDefaultValues } from './hooks/useInitDefaultValues'
import { ScheduleInfo } from './ui/schedule-info/schedule-info.component'
import { IStudiosCollapsesDefaults } from './ui/studios-collapses/studios-collapses.types'
import { getCellId } from './utils/cell'
import { getDefaultFigurePreset } from './utils/figure'
import './work-time-schedule.styles.less'

export const WorkTimeSchedule: FC<IWorkTimeScheduleProps> = ({ onChange, defaultWorkTime, isLoading = false }) => {
  // SECTION: Store
  const studios = useSelector(getStudios)
  const dispatch = useDispatch()

  // SECTION: State
  const [selectedStudio, setSelectedStudio] = useState<Nullable<ICurrentStudioSlice>>(null)
  const [selectedFigure, setSelectedFigure] = useState<Nullable<IFigure>>(null)
  const [figurePreset, setFigurePreset] = useState<IFigurePreset>(getDefaultFigurePreset())
  const [isMouseDown, setIsMouseDown] = useState(false)

  const [schedulesStudios, setSchedulesStudios] = useState<IWorkTimeScheduleStudio[]>([])
  const [defaultsCollapsesStudios, setDefaultsCollapsesStudios] = useState<IStudiosCollapsesDefaults | null>(null)

  // SECTION: Custom hook
  const [defaultFigures, defaultFigure, defaultFigurePreset, defaultStudio, defaultScheduleStudios] = useDefaultValues(
    defaultWorkTime as unknown as Record<string, any[]>,
    studios
  )
  useInitDefaultValues(
    defaultFigures,
    defaultFigure,
    defaultFigurePreset,
    defaultStudio,
    defaultScheduleStudios,
    setSelectedStudio,
    setSelectedFigure,
    setFigurePreset,
    setDefaultsCollapsesStudios,
    setSchedulesStudios
  )

  // SECTION: Actions
  const updateSchedulesStudios = ({
    type,
    syntheticEvent,
    id,
    day,
    studio,
    tagType,
    timeRange,
    cellId,
  }: IHourCellEvent) => {
    switch (type) {
      case FIGURE_BUTTON_EVENT_TYPE.CREATE:
        setSchedulesStudios([...schedulesStudios, { id, studio, day, time: timeRange, tagType, cellId }])
        break
      case FIGURE_BUTTON_EVENT_TYPE.UPDATE:
        setSchedulesStudios(
          schedulesStudios.map(schedule => {
            if (schedule.id === id && schedule.cellId === cellId) {
              return { ...schedule, time: timeRange }
            }
            return schedule
          })
        )
        break
      case FIGURE_BUTTON_EVENT_TYPE.DELETE:
        setSchedulesStudios(schedulesStudios.filter(schedule => schedule.id !== id || schedule.cellId !== cellId))
        break
    }
  }

  // SECTION: CONFIG
  const columns = [
    {
      title: '',
      dataIndex: 'day',
      key: 'day',
      className: 'day-cell-container',
      render: (text: string) => <Typography.Paragraph>{text}</Typography.Paragraph>,
    },
    ...Array.from({ length: 24 }, (_, i) => ({
      title: String(i),
      dataIndex: `hour-${i}`,
      key: `hour-${i}`,
      className: 'hour-cell-container',
      render: (_: any, record: { key: string; day: string }) => {
        const cellId = getCellId(i, Number(record.key))
        const cellFigures = defaultFigures?.filter(figure => figure.cellId === cellId)

        return (
          <HourCell
            key={cellId}
            cellId={cellId}
            column={i}
            row={Number(record.key)}
            studio={selectedStudio}
            selectedFigure={selectedFigure}
            figurePreset={figurePreset}
            defaultFigures={cellFigures}
            onClick={updateSchedulesStudios}
            isMouseDown={isMouseDown}
            setIsMouseDown={setIsMouseDown}
          />
        )
      },
    })),
  ]

  const dataSource = [
    { key: '1', day: 'пн' },
    { key: '2', day: 'вт' },
    { key: '3', day: 'ср' },
    { key: '4', day: 'чт' },
    { key: '5', day: 'пт' },
    { key: '6', day: 'сб' },
    { key: '7', day: 'вс' },
  ]

  // SECTION: Lifecycle
  useEffect(() => {
    dispatch(studioEntityActions.fetchAllStudios())
  }, [])

  useEffect(() => {
    onChange && onChange(schedulesStudios)
  }, [schedulesStudios, onChange])

  return (
    <Flex vertical gap="large" className="work-time-schedule">
      <Typography.Title level={4}>Активируйте студию или зал</Typography.Title>
      <StudiosCollapse
        studios={studios}
        setCurrentStudio={setSelectedStudio}
        setFigurePreset={setFigurePreset}
        defaults={defaultsCollapsesStudios}
      />

      <Flex vertical gap="small">
        <Flex className="work-time-schedule__header" justify="center" align="center">
          <Typography.Title className="work-time-schedule__header-title" level={4}>
            Отметьте рабочее время
          </Typography.Title>
          <Button shape="round" type="dashed" size="large" className="work-time-schedule__selected-room">
            <Flex gap="small">
              <WarningOutlined className="work-time-schedule__icon" />

              {!!figurePreset?.tag?.type && figurePreset.details.name !== 'Неизвестная комната' ? (
                <>
                  <Typography.Paragraph>Выбрано</Typography.Paragraph>
                  {selectedStudio?.name && (
                    <Typography.Paragraph className="work-time-schedule__studio-title">
                      {selectedStudio?.name}
                    </Typography.Paragraph>
                  )}
                  <Tag color={figurePreset.figure.color}>{figurePreset.details.name}</Tag>
                </>
              ) : (
                <Typography.Paragraph>Выберите зал</Typography.Paragraph>
              )}
            </Flex>
          </Button>
        </Flex>

        <Table
          loading={isLoading}
          bordered
          className="work-time-schedule__table"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />

        <ScheduleInfo />
      </Flex>
    </Flex>
  )
}

import { all, put, select, takeLatest } from 'redux-saga/effects'
import { createMatchSelector, getSearch } from 'connected-react-router'
import { match } from 'react-router-dom'
import * as H from 'history'
import dayjs from 'dayjs'

import { api } from '../../../../api/api'
import { isExercisesCancelConflict } from '../../../../api/utils/exercises-api.utils'
import { genSchedulePageParams } from '../../../../pages/schedule-page/schedule-page.utils'
import { SchedulePageUrlParams } from '../../../../pages/schedule-page/schedule-page.types'
import { AppPath } from '../../../../types/path.types'
import { AppState } from '../../../app.store'
import { modalActions } from '../../../common/modal/modal.slice'
import { AppModal } from '../../../../types/modal.types'
import { schedulePageListActions } from './schedule-page-list.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { genSchedulePageListTimetable } from './schedule-page-list.selectors'

function* fetchPageData(action: ReturnType<typeof schedulePageListActions.fetchPageData>) {
  try {
    const { studioId, roomId, dateFrom, dateTo, trainerId, directionId, extended } = action.payload
    const [exercisesResponse, breaksResponse, studioResponse, studiosRoomsResponse]: [
      Awaited<ReturnType<typeof api.exercisesService.fetchAll>>,
      Awaited<ReturnType<typeof api.breaksService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosService.fetchById>>,
      Awaited<ReturnType<typeof api.studiosRoomsService.fetchAll>>
    ] = yield all([
      callApi(api.exercisesService.fetchAll, {
        studioId,
        roomId,
        dateFrom,
        dateTo,
        trainerId,
        directionId,
        includeCompleted: true,
        includeAvailableSlots: extended,
      }),
      callApi(api.breaksService.fetchAll, {
        studioId,
        roomId,
        dateFrom,
        dateTo,
      }),
      callApi(api.studiosService.fetchById, studioId),
      callApi(api.studiosRoomsService.fetchAll, studioId),
    ])

    yield put(
      schedulePageListActions.fetchPageDataSuccess({
        exercises: exercisesResponse.data,
        breaks: breaksResponse.data,
        studio: studioResponse.data,
        studiosRooms: studiosRoomsResponse.data.content,
      })
    )
  } catch (e) {
    yield put(schedulePageListActions.fetchPageDataError(new Error()))
  }
}

function* cancelExercise(action: ReturnType<typeof schedulePageListActions.cancelExercise>) {
  try {
    yield callApi(api.exercisesService.cancel, action.payload)
    yield put(schedulePageListActions.cancelExerciseSuccess())
    yield put(modalActions.closeLast())

    const search: H.Search = yield select(getSearch)
    const { params }: match<SchedulePageUrlParams> = yield select(
      createMatchSelector<AppState, SchedulePageUrlParams>(AppPath.SCHEDULE)
    )

    const pageParams = genSchedulePageParams(params, search)

    yield put(schedulePageListActions.fetchPageData(pageParams))
  } catch (e) {
    yield put(schedulePageListActions.cancelExerciseError(new Error()))

    if (isExercisesCancelConflict(e)) {
      yield put(
        modalActions.replaceAll({
          modal: AppModal.SCHEDULE_PAGE_MODAL_ERROR,
        })
      )
    }
  }
}

function* cancelBreak(action: ReturnType<typeof schedulePageListActions.cancelBreak>) {
  try {
    yield callApi(api.breaksService.cancel, action.payload)
    yield put(schedulePageListActions.cancelBreakSuccess())
    yield put(modalActions.closeLast())

    const search: H.Search = yield select(getSearch)
    const currentTimetable: ReturnType<typeof genSchedulePageListTimetable> = yield select(genSchedulePageListTimetable)

    const { params }: match<SchedulePageUrlParams> = yield select(
      createMatchSelector<AppState, SchedulePageUrlParams>(AppPath.SCHEDULE)
    )

    const { date, ...pageParams } = genSchedulePageParams(params, search)

    const safeDate = date ? date : dayjs().format('YYYY-MM-DD')
    const dateParams = { dateFrom: safeDate, dateTo: safeDate }

    if (currentTimetable === 'weekly') {
      const targetDate = dayjs(safeDate)
      dateParams.dateFrom = targetDate.startOf('isoWeek').format('YYYY-MM-DD')
      dateParams.dateTo = targetDate.endOf('isoWeek').format('YYYY-MM-DD')
    }

    yield put(schedulePageListActions.fetchPageData({ ...pageParams, ...dateParams }))
  } catch (e) {
    yield put(schedulePageListActions.cancelBreakError(new Error()))
  }
}

export function* schedulePageListSagas() {
  yield takeLatest(schedulePageListActions.fetchPageData, fetchPageData)
  yield takeLatest(schedulePageListActions.cancelExercise, cancelExercise)
  yield takeLatest(schedulePageListActions.cancelBreak, cancelBreak)
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { NEmployeesScheduleCreate } from './employees-schedule-create.types'

const initialState: NEmployeesScheduleCreate.IState = {
  isLoading: false,
  error: null,
}

export const { actions: employeesScheduleCreateActions, reducer: employeesScheduleCreateReducer } = createSlice({
  name: '@@employees-schedule-create',
  initialState,
  reducers: {
    create: (
      state: Draft<NEmployeesScheduleCreate.IState>,
      _: PayloadAction<NEmployeesScheduleCreate.ICreatePayload>
    ) => {
      state.isLoading = true
    },
    createSuccess: (state: Draft<NEmployeesScheduleCreate.IState>) => {
      state.isLoading = false
    },
    createError: (state: Draft<NEmployeesScheduleCreate.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'
import { SearchApi } from '@api/types/search-api.types'
import { ClientsAutocompleteOptionDataItem } from '@components/clients/clients-autocomplete/clients-autocomplete-option/clients-autocomplete-option.types'

import { EmployeesApi } from '../api/types/employees-api.types'
import { EmployeesAutocompleteOptionDataItem } from '../components/employees/employees-autocomplete-option/employees-autocomplete-option.types'
import {
  EmployeesFormValues,
  EmployeesFormValuesDTO,
} from '../components/employees/employees-form/employees-form.types'
import { EmployeesTableRow } from '../components/employees/employees-table/employees-table.types'
import { formatFullName } from '../format/text.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { PositionType } from '../types/positions.types'
import { formatFormValueToPhoneNumber } from '../format/phone.format'

export function mapEmployeesToOptions(employees: Nullable<EmployeesApi.Employee[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(employees)) {
    return employees.map((employee: EmployeesApi.Employee): DefaultOptionType => {
      const { firstName, lastName } = employee

      const fullName = formatFullName(firstName, lastName)

      return {
        value: fullName,
        label: fullName,
      }
    })
  }
}

export function mapEmployeesToEmployeesTableRowList(
  employees: Nullable<EmployeesApi.Employee[]>
): Nullable<EmployeesTableRow[]> {
  if (isDefAndNotEmpty(employees)) {
    return employees.map((employee: EmployeesApi.Employee): EmployeesTableRow => {
      const phone = employee.phone?.replace(/\D/g, '')
      return {
        id: employee.id,
        firstName: employee.firstName,
        middleName: employee.middleName,
        lastName: employee.lastName,
        birthDate: employee.birthDate,
        photo: employee.photo,
        phone: phone,
        email: employee.email,
        studiosCount: employee.studios?.length,
        franchise: employee.organisation?.name,
        position: employee.position?.name,
      }
    })
  }

  return null
}

export function mapEmployeesToEmployeesOptions(employees: Nullable<EmployeesApi.Employee[]>): DefaultOptionType[] {
  if (isDefAndNotEmpty(employees)) {
    return employees.map((employee: EmployeesApi.Employee) => ({
      value: employee.id,
      label: formatFullName(employee.firstName, employee.lastName, employee.middleName),
    }))
  }

  return []
}

export function genEmployeeCreateDTO(
  values: EmployeesFormValuesDTO,
  employeePositionType: PositionType
): EmployeesApi.EmployeeCreateDTO {
  const createdEmployee = {
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    birthDate: values.birthDate,
    photo: values.photo,
    phone: formatFormValueToPhoneNumber(values.phone),
    email: values.email,
    position: { id: values.position },
    organisation: { id: values.organisation },
    grade: isDef(values.grade) ? { id: values.grade } : null,
    studios: [],
  }
  if (employeePositionType === PositionType.EXECUTOR) {
    const bio = values.bio
    const exerciseTypeIds = isDefAndNotEmpty(values.exerciseTypeIds) ? values.exerciseTypeIds : []
    const exerciseDirections = values.exerciseDirections
      ? values.exerciseDirections.map(exerciseDirection => {
          return { id: exerciseDirection }
        })
      : []
    Object.assign(createdEmployee, { bio }, { exerciseTypeIds }, { exerciseDirections })
  }

  return createdEmployee
}

export function genEmployeeFormValues(employee: Nullable<EmployeesApi.Employee>): Nullable<EmployeesFormValues> {
  if (isDef(employee)) {
    const phone = employee.phone?.replace(/\D/g, '')
    return {
      firstName: employee.firstName,
      middleName: employee.middleName,
      lastName: employee.lastName,
      birthDate: dayjs(employee.birthDate),
      photo: employee.photo,
      phone: phone ? `+${phone}` : '',
      email: employee.email,
      position: employee.position?.id,
      organisation: employee.organisation?.id,
      grade: employee.grade?.id,
      bio: employee.bio,
      inn: employee.inn,
      exerciseDirections: isDefAndNotEmpty(employee.exerciseDirections)
        ? employee.exerciseDirections.map(exerciseDirection => exerciseDirection.id)
        : [],
      exerciseTypeIds: isDefAndNotEmpty(employee.exerciseTypes)
        ? employee.exerciseTypes.map(exerciseType => exerciseType.id)
        : [],
    }
  }

  return null
}

export function genEmployeeUpdateDTO(
  values: Nullable<EmployeesFormValuesDTO>,
  employee: Nullable<EmployeesApi.Employee>,
  employeePositionType: PositionType
): Nullable<EmployeesApi.EmployeeUpdateDTO> {
  if (isDef(values) && isDef(employee)) {
    const updatedEmployee = {
      id: employee.id,
      userId: employee.userId,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      birthDate: values.birthDate,
      photo: values.photo,
      phone: formatFormValueToPhoneNumber(values.phone),
      email: values.email,
      position: { id: values.position },
      grade: isDef(values.grade) ? { id: values.grade } : null,
      organisation: { id: values.organisation },
      studios: [],
      inn: values.inn === '' ? null : values.inn,
    }
    if (employeePositionType === PositionType.EXECUTOR) {
      const bio = values.bio
      const exerciseTypeIds = isDefAndNotEmpty(values.exerciseTypeIds) ? values.exerciseTypeIds : []
      const exerciseDirections = values.exerciseDirections
        ? values.exerciseDirections.map(exerciseDirection => {
            return { id: exerciseDirection }
          })
        : []
      Object.assign(updatedEmployee, { bio }, { exerciseTypeIds }, { exerciseDirections })
    }
    return updatedEmployee
  }

  return null
}

export function mapEmployeesToEmployeesAutocompleteOptionDataItems(
  employees: Nullable<SearchApi.SearchItem[]>
): Nullable<EmployeesAutocompleteOptionDataItem[]> {
  if (isDefAndNotEmpty(employees)) {
    return employees?.map((employee: SearchApi.SearchItem): EmployeesAutocompleteOptionDataItem => {
      const { id, details, label } = employee

      return {
        slug: id,
        label: label,
        phone: details,
      }
    })
  }

  return null
}

import * as React from 'react'
import { Button, DatePicker, Input, Table, Tooltip, Typography } from 'antd'
import clsx from 'clsx'
import { ColumnsType } from 'antd/es/table'
import { SearchOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { ExpandedRowRender } from 'rc-table/lib/interface'

import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { TableCellPrice } from '../../table-cells/table-cell-price/table-cell-price.component'
import {
  ClientsTransactionsTableDataItem,
  ClientsTransactionsTableDataItemProduct,
} from './clients-transactions-table.types'
import { ClientsTransactionsTableUsedInfo } from './clients-transactions-table-used-info/clients-transactions-table-used-info.component'
import { ClientsTransactionsTableActionsEvents } from './clients-transactions-table-actions/clients-transactions-table-actions.types'
import { firstItem } from '../../../utils/list.utils'
import { TransactionsTableStatus } from '../../transactions/transactions-table/transactions-table-status/transactions-table-status.component'
import { ClientsTransactionsTableActions } from './clients-transactions-table-actions/clients-transactions-table-actions.component'
import { isDefAndNotEmpty, Nullable } from '../../../types/lang.types'
import { genTransactionsTableProductsColumns } from '../../transactions/transactions-table/transactions-table.utils'
import { ClientsTransactionsTablePayment } from './clients-transactions-table-payment/clients-transactions-table-payment.component'
import { TableCellDateTime } from '../../table-cells/table-cell-date-time/table-cell-date-time.component'
import { formatRublesPenniesCurrency } from '../../../format/number.format'
import { TableCellTagProductType } from '../../../components/table-cells/table-cell-tag-product-type/table-cell-tag-product-type.component'
import { formatClientsPaymentMethod } from '../../../format/text.format'
import { checkIsServiceType } from '../../../utils/status.utils'
import { ClientsBookingsApi } from '../../../api/types/clients-bookings-api.types'
import { TransactionStatus } from '../../../types/transactions.types'

export function genClientsTransactionsTableColumns(
  events: ClientsTransactionsTableActionsEvents,
  onBooking: (studioId: string, scheduleId: string) => void,
  onHoverUsed: (bookingId: string) => void,
  isUsedBookingLoading: boolean,
  usedBooking: Nullable<ClientsBookingsApi.ClientBooking>
): ColumnsType<ClientsTransactionsTableDataItem> {
  const { onBarcode, onRefundSumHandler } = events

  return [
    {
      title: 'Дата',
      key: 'date-time',
      width: 100,
      onFilter: (value, record) => {
        const [start, end] = value as any
        return dayjs(record.createDate).isBetween(start, end)
      },
      filterDropdown: ({ confirm, clearFilters, setSelectedKeys, selectedKeys }) => {
        return (
          <div className="clients-transaction-table__product-filter-wrapper">
            <DatePicker.RangePicker
              value={selectedKeys[0] as any}
              onChange={e => {
                setSelectedKeys([e as any])
              }}
              allowClear={false}
            />
            <div className="clients-transaction-table__product-filter-buttons">
              <Button type="primary" onClick={() => confirm()} size="small">
                Ок
              </Button>
              <Button onClick={clearFilters && (() => clearFilters())} size="small">
                Сбросить
              </Button>
            </div>
          </div>
        )
      },
      render: (_, transaction) => <TableCellDateTime date={transaction.createDate} isShort format="HH:mm" />,
    },
    {
      title: 'Покупка',
      key: 'products',
      fixed: 'left',
      render: (_, transaction) => {
        const { products } = transaction

        if (products.length > 1) {
          const text = products.map(product => product.name).join(', ')

          return (
            <Typography.Title className="clients-transaction-table__product-name" level={5}>
              {text}
            </Typography.Title>
          )
        }

        return (
          <div>
            <TableCellTagProductType productType={transaction.products[0].type} />
            <Typography.Title level={5} className="clients-transaction-table__product-name">
              {firstItem(products)?.name}
            </Typography.Title>
          </div>
        )
      },
    },
    {
      title: 'Кто продал?',
      key: 'seller',
      width: 150,
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Поиск продавца"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: '100%', marginBottom: 8 }}
          >
            Поиск
          </Button>
          <Button onClick={clearFilters && (() => clearFilters())} size="small" style={{ width: '100%' }}>
            Сбросить
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const filterValue = String(value).toLowerCase()
        return (
          !!record.seller?.firstName.toLowerCase().includes(filterValue) ||
          !!record.seller?.lastName.toLowerCase().includes(filterValue)
        )
      },
      render: (_, transaction) => {
        return (
          <TableCellText
            text={transaction.seller ? `${transaction.seller.lastName} ${transaction.seller.firstName}` : ''}
          />
        )
      },
    },
    {
      title: 'Оплата',
      dataIndex: 'toPay',
      key: 'toPay',
      fixed: 'right',
      width: 150,
      filters: [
        { text: 'Оплата картой', value: 'CARD' },
        { text: 'Наличные', value: 'CASH' },
        { text: 'СМС / Ссылка', value: 'SMS' },
        { text: 'Через приложение', value: 'APP' },
        { text: 'Виджет', value: 'WIDGET' },
        { text: 'Без оплаты', value: 'NO_PAYMENT' },
      ],
      onFilter: (value, transaction) => transaction.paymentMethod === value,
      render: (_, transaction) => {
        const url = transaction.cardPaymentInfo && transaction.cardPaymentInfo.paymentUrl
        return (
          <div className="clients-transaction-table__common-wrapper">
            {!!transaction.discount && (
              <Tooltip title={<span>{transaction.discountReason}</span>}>
                <span className="clients-transaction-table__discount-reason">
                  {formatRublesPenniesCurrency(transaction.sum)}
                </span>
              </Tooltip>
            )}
            <span
              style={{
                fontSize: '14px',
                lineHeight: '22px',
                fontWeight: 600,
              }}
            >
              {formatRublesPenniesCurrency(transaction.toPay)}
            </span>
            <ClientsTransactionsTablePayment value={transaction.paymentMethod} paymentLink={url} />
          </div>
        )
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      filters: [
        { text: 'Оплачено', value: 'PAID' },
        { text: 'Не оплачено', value: 'UNPAID' },
        { text: 'Ожидание', value: 'WAITING' },
        { text: 'Возврат', value: 'REFUND' },
      ],
      onFilter: (value, transaction) => transaction.status === value,
      width: 150,
      render: (_, transaction) => {
        const scheduleId = firstItem(transaction.products)?.linkedExerciseId
        const studioId = transaction.paymentStudioId
        const hasGroupLink = !!(scheduleId && studioId)

        const isService = checkIsServiceType(transaction.products[0].type)

        return (
          <div className="clients-transaction-table__common-wrapper">
            <Tooltip
              overlayInnerStyle={{
                width: 332,
              }}
              title={
                <div>
                  {transaction.cardPaymentInfo?.paymentId && (
                    <div>
                      <span>
                        ID транзакции банка: <br /> {transaction.cardPaymentInfo?.paymentId}
                      </span>
                    </div>
                  )}
                  <div>
                    <span className="clients-transaction-table__transaction-label">Транзакцию</span> принял сотрудник{' '}
                    <span className="clients-transaction-table__seller-label">
                      {transaction.seller?.lastName} {transaction.seller?.firstName}
                    </span>{' '}
                    {dayjs(transaction.createDate).format('DD MMM в HH:mm')},{' '}
                    <span
                      style={{
                        textTransform: 'lowercase',
                      }}
                    >
                      {formatClientsPaymentMethod(transaction.paymentMethod)}
                    </span>
                  </div>
                  {firstItem(transaction.products)?.refunded && (
                    <div>
                      <span>Отмену</span> сделал сотрудник{' '}
                      <span>{firstItem(transaction.products)?.refaunder?.name}</span>
                    </div>
                  )}
                </div>
              }
            >
              <span
                style={{
                  cursor: 'pointer',
                }}
              >
                <TransactionsTableStatus value={transaction.status} />
              </span>
            </Tooltip>
            {isService && transaction.status === TransactionStatus.PAID && (
              <ClientsTransactionsTableUsedInfo
                isUsedBookingLoading={isUsedBookingLoading}
                usedBooking={usedBooking}
                onHoverUsed={onHoverUsed}
                transaction={transaction}
              />
            )}
          </div>
        )
      },
    },
    {
      title: 'Действия',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (_, transaction) => (
        <ClientsTransactionsTableActions
          transaction={transaction}
          onBarcode={onBarcode}
          onRefundSumHandler={onRefundSumHandler}
        />
      ),
    },
  ]
}

export function genClientsTransactionsTableColumnsProductsColumns(): ColumnsType<ClientsTransactionsTableDataItemProduct> {
  return [
    {
      title: 'Наименование',
      key: 'products',
      render: (_, product) => <TableCellText text={product.name} />,
    },
    {
      title: 'Стоимость',
      dataIndex: 'sum',
      key: 'sum',
      render: (_, product) => <TableCellPrice value={product.cost} />,
    },
    {
      title: 'Кол-во',
      dataIndex: 'count',
      key: 'count',
      render: (_, product) => <TableCellText text={String(product.count)} />,
    },
    {
      title: 'Сумма',
      key: 'total',
      render: (_, product) => {
        const total = product.cost * product.count

        return <TableCellPrice value={total} />
      },
    },
  ]
}

export const genClientsTransactionsTableExpandedRowRender: ExpandedRowRender<ClientsTransactionsTableDataItem> = (
  record: ClientsTransactionsTableDataItem
) => {
  const columns = genTransactionsTableProductsColumns()

  return <Table dataSource={record.products} columns={columns} pagination={false} loading={false} />
}

export const genClientsTransactionsTableRowExpandable = (transaction: ClientsTransactionsTableDataItem): boolean => {
  return isDefAndNotEmpty(transaction.products) && transaction.products.length > 1
}

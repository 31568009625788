import { Rule } from 'antd/lib/form'
import dayjs, { Dayjs } from 'dayjs'

import { formatDayjsToDate, genDefaultTimeFormat } from '../../../format/date.format'
import { isDef } from '../../../types/lang.types'
import { ExercisesGroupEditFormValues, ExercisesGroupEditFormValuesDTO } from './exercises-group-edit-form.types'

export function genExercisesGroupEditFormTimeFromValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите время начала' }]
}

export function genExercisesGroupEditFormTimeToValidationRules(): Rule {
  return {
    validator(_, value) {
      if (!value || value.length !== 2) {
        return Promise.reject('Выберите начальное и конечное время')
      }
      const [start, end] = value.map((time: string) => dayjs(time, 'HH:mm').valueOf())
      if (start >= end) {
        return Promise.reject('Заполните корректно время')
      }

      return Promise.resolve()
    },
  }
}

export function genExercisesGroupEditFormStudiosRoomsValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите зал' }]
}

export function genExercisesGroupEditFormDirectionValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите групповое направление' }]
}

export const genExercisesGroupEditFormDisabledDate = (current: Dayjs | null): boolean => {
  if (!current) return false
  const today = dayjs().startOf('day')
  return current.isBefore(today)
}

export function genExercisesGroupEditFormValuesDTO(
  values: ExercisesGroupEditFormValues,
  studioOffset: number
): ExercisesGroupEditFormValuesDTO {
  const { date, time, trainers, room, direction, maxClientsCount, comment, type, masterService, subService } = values
  const [timeFrom, timeTo] = time

  const timeFromDTO = isDef(timeFrom)
    ? dayjs(timeFrom, genDefaultTimeFormat()).utcOffset(studioOffset, true).format()
    : null

  const timeToDTO =
    isDef(timeTo) && timeTo !== '00:00'
      ? dayjs(timeTo, genDefaultTimeFormat()).utcOffset(studioOffset, true).format()
      : dayjs(timeFrom, genDefaultTimeFormat()).add(1, 'hours').utcOffset(studioOffset, true).format()

  return {
    trainers,
    room,
    direction: direction ?? subService,
    maxClientsCount,
    time: [timeFromDTO, timeToDTO],
    date: formatDayjsToDate(date),
    comment,
    type,
    masterService,
    subService,
  }
}

import * as React from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { genScheduleTableColumns } from './schedule-table.utils'
import { CustomCard } from '../../custom-card/custom-card.component'
import { ScheduleTableDataItem, ScheduleTableEvents } from './schedule-table.types'
import './schedule-table.styles.less'

interface Props extends ScheduleTableEvents {
  data: ScheduleTableDataItem[]
  loading?: boolean
}

export const ScheduleTable: React.FC<Props> = props => {
  const { data, loading } = props
  const { onEdit, onCancel } = props

  const columns: ColumnsType<ScheduleTableDataItem> = React.useMemo(
    () => genScheduleTableColumns({ onEdit, onCancel }),
    [onEdit, onCancel]
  )

  const scroll = React.useMemo(() => ({ x: 1300 }), [])

  return (
    <CustomCard>
      <Table
        className="table schedule-table"
        rowKey="id"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={false}
        scroll={scroll}
      />
    </CustomCard>
  )
}

import { AxiosResponse } from 'axios'

import { Nullable } from '../../types/lang.types'
import { HttpConnector } from '../connectors/http.connector'
import { BreaksApi } from '../types/breaks.types'

export class BreaksService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (params?: Nullable<BreaksApi.BreaksFetchAllParams>): Promise<AxiosResponse<BreaksApi.Break[]>> => {
    const response = this.httpConnector.get<BreaksApi.Break[]>('/breaks', { params })
    return response
  }

  public cancel = (id: string): Promise<AxiosResponse<void>> => {
    return this.httpConnector.delete<void>(`/breaks/${id}`)
  }
}

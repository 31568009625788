import { ClientsBookingsApi } from '../api/types/clients-bookings-api.types'
import { ClientsBookingsTableBaseDataItem } from '../components/clients/clients-bookings-tables/clients-bookings-table-base/clients-bookings-table-base.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import {
  mapDictionaryItemsListToEntityItemsList,
  mapDictionaryItemToEntityItem,
  mapPaymentTypeToClient,
} from './api.mapping'

export function mapClientsBookingsToClientsBookingsTableBaseDataItems(
  bookings: Nullable<ClientsBookingsApi.ClientBooking[]>
): Nullable<ClientsBookingsTableBaseDataItem[]> {
  if (isDefAndNotEmpty(bookings)) {
    return bookings.reduce<ClientsBookingsTableBaseDataItem[]>(
      (acc: ClientsBookingsTableBaseDataItem[], booking: ClientsBookingsApi.ClientBooking) => {
        const paymentType = mapPaymentTypeToClient(booking.paymentType)
        const exerciseDirection = mapDictionaryItemToEntityItem(booking.exerciseDirection)
        const studio = mapDictionaryItemToEntityItem(booking.studio)
        const trainers = mapDictionaryItemsListToEntityItemsList(booking.trainers)

        if (isDef(paymentType) && isDef(exerciseDirection) && isDef(studio) && isDef(trainers)) {
          const clientsBookingsTableBaseDataItem: ClientsBookingsTableBaseDataItem = {
            id: booking.id,
            createDate: booking.createDate,
            createdBy: booking.createdBy,
            exerciseDate: booking.exerciseDate,
            visitConfirmed: booking.visitConfirmed,
            canceled: booking.isCancelled,
            exerciseId: booking.exerciseId,
            cancelledBy: booking.cancelledBy,
            canceledAt: booking.cancelledAt,
            isCanceled: booking.isCancelled,
            exerciseDirection,
            exerciseDuration: booking.exerciseDuration,
            studio,
            paymentType,
            client: booking.client,
            clientSubscriptionId: booking.clientSubscriptionId,
            trainers: trainers,
            transactionId: booking.transactionId,
            exerciseType: booking.exerciseType,
          }

          acc.push(clientsBookingsTableBaseDataItem)
        }
        acc.sort((a, b) => (a.exerciseDate < b.exerciseDate ? 1 : -1))
        return acc
      },
      []
    )
  }

  return null
}

import * as React from 'react'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import { Autocomplete } from '../../controls/autocomplete/autocomplete.component'
import { ClientsAutocompleteInput } from './clients-autocomplete-input/clients-autocomplete-input.component'
import { ClientsAutocompleteOption } from './clients-autocomplete-option/clients-autocomplete-option.component'
import { genClientsAutocompleteItemToString } from './clients-autocomplete.utils'
import { ClientsAutocompleteProps } from './clients-autocomplete.types'
import './clients-autocomplete.styles.less'

export const ClientsAutocomplete: React.FC<ClientsAutocompleteProps> = props => {
  const { className, size, placeholder, suffix } = props
  const { value, defaultValue, options } = props
  const { disabled, loading } = props
  const { onInputChange, onChange, onBlur, onFocus, onSelect } = props

  const onInputChangeHandler = (value: string): void => {
    onInputChange?.(value)
  }

  const renderSlotComponent = (closeMenu: () => void) => (
    <div className="add-button--wrapper">
      <Button
        className="add-button"
        type="link"
        icon={<PlusCircleOutlined className="add-button--icon" />}
        onClick={() => closeMenu()}
      >
        <span className="add-button--text">Новый клиент</span>
      </Button>
    </div>
  )

  return (
    <Autocomplete
      className={className}
      value={value}
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onInputChange={onInputChangeHandler}
      onSelect={onSelect}
      loading={loading}
      disabled={disabled}
      itemToStringFn={genClientsAutocompleteItemToString}
      clearInputAfterSelect={false}
      InputComponent={ClientsAutocompleteInput}
      OptionComponent={ClientsAutocompleteOption}
      renderSlotComponent={renderSlotComponent}
      size={size}
      placeholder={placeholder}
      suffix={suffix}
    />
  )
}

import { FC, lazy, Suspense } from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'

import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { isDef, isDefAndNotEmpty } from '../../../types/lang.types'
import { useSchedulePageTable } from './schedule-page-table.hook'
import { genSchedulePageListTimetable } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'

export const SchedulePageTable: FC<ReturnType<typeof useSchedulePageTable>> = ({
  schedule,
  studioWorkTime,
  studiosRoomsTags,
  studioOffset,
  date,
  onAddHandler,
  onViewHandler,
  onEditStudioHandler,
  onCancelHandler,
  studioRoomsWorkTime,
}) => {
  // Dynamic Components
  const WeeklyTimetable = lazy(() =>
    import('../../../components/ui/timetable/views/weekly-timetable/weekly-timetable.component').then(module => ({
      default: module.WeeklyTimetable,
    }))
  )
  const DailyTimetable = lazy(() =>
    import('../../../components/ui/timetable/views/daily-timetable/daily-timetable.component').then(module => ({
      default: module.DailyTimetable,
    }))
  )

  // Hooks
  const currentTimetable = useSelector(genSchedulePageListTimetable)

  // Props
  const timetableProps = {
    tags: studiosRoomsTags,
    events: schedule,
    studioOffset,
    date,
    workTime: studioWorkTime,
    roomsWorkTime: studioRoomsWorkTime,
    onAdd: onAddHandler,
    onView: onViewHandler,
    onCancel: onCancelHandler,
  }

  // Render
  if (!isDefAndNotEmpty(studiosRoomsTags)) {
    return (
      <PageEmpty description="У студии еще нет залов">
        <Button type="primary" onClick={onEditStudioHandler}>
          Создайте первый зал
        </Button>
      </PageEmpty>
    )
  } else if (isDef(date)) {
    return (
      <Suspense fallback={<PageLoader />}>
        {currentTimetable === 'weekly' && <WeeklyTimetable {...timetableProps} />}
        {currentTimetable === 'daily' && <DailyTimetable {...timetableProps} />}
      </Suspense>
    )
  }
  return <PageEmpty description="На эту дату нет расписания" />
}

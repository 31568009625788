import { DefaultOptionType } from 'antd/lib/select'

import { TrainersApi } from '../api/types/trainers-api.types'
import { ExercisesFormTrainers } from '../components/exercises/exercises-form/exercises-form.types'
import { MasterServicesFormTrainersWithGrades } from '../components/master-services/master-services-form/master-services-form.types'
import { formatFullName } from '../format/text.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'

export function mapTrainersToOptions(trainers: Nullable<TrainersApi.Trainer[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(trainers)) {
    return trainers.reduce((acc: DefaultOptionType[], trainer: TrainersApi.Trainer) => {
      const { id, firstName, lastName } = trainer

      acc.push({
        value: id,
        label: formatFullName(firstName, lastName),
      })
      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function mapTrainersToTrainersWithGrades(
  trainers: Nullable<TrainersApi.Trainer[]>
): Nullable<MasterServicesFormTrainersWithGrades[]> {
  if (isDef(trainers)) {
    return trainers.reduce<MasterServicesFormTrainersWithGrades[]>((acc, trainer: TrainersApi.Trainer) => {
      acc.push({
        id: trainer.id,
        grade: trainer.grade ? trainer.grade : null,
        position: trainer.position,
      })

      return acc
    }, [])
  }
  return null
}

export function mapTrainersToExercisesForm(
  trainers: Nullable<TrainersApi.Trainer[]>
): Nullable<ExercisesFormTrainers[]> {
  if (isDef(trainers)) {
    return trainers.map((trainer: TrainersApi.Trainer) => ({
      id: trainer.id,
      value: trainer.id,
      label: `${trainer.lastName} ${trainer.firstName}`,
      name: `${trainer.lastName} ${trainer.firstName}`,
      exerciseTypes: trainer.exerciseTypes,
    }))
  }
  return null
}

import { mapExercisesTypesToExercisesForm } from '@mapping/exercises-types.mapping'
import { createSelector } from '@reduxjs/toolkit'
import { mapTrainersToExercisesForm } from '@mapping/trainers.mapping'

import { mapDirectionsToOptions } from '../../../../mapping/directions.mapping'
import { mapStudiosRoomsToOptions } from '../../../../mapping/studios-rooms.mapping'
import { AppState } from '../../../app.store'

const getTrainersInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageEdit.trainers
const getStudiosRoomsInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageEdit.studiosRooms
const getDirectionsInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageEdit.directions
const getExercisesTypesInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageEdit.exercisesTypes

export const getScheduleGroupPageEditIsLoading = (state: AppState) =>
  state.scheduleGroupPage.scheduleGroupPageEdit.isLoading

export const getScheduleGroupPageEditIsLoaded = (state: AppState) =>
  state.scheduleGroupPage.scheduleGroupPageEdit.isLoaded

export const getScheduleGroupPageEditTrainersOptions = createSelector(getTrainersInternal, trainers =>
  mapTrainersToExercisesForm(trainers?.content)
)

export const getScheduleGroupPageEditStudiosRoomsOptions = createSelector(getStudiosRoomsInternal, studiosRooms =>
  mapStudiosRoomsToOptions(studiosRooms)
)

export const getScheduleGroupPageEditDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)

export const getScheduleGroupPageEditTypesOptions = createSelector(getExercisesTypesInternal, exercisesTypes => {
  return mapExercisesTypesToExercisesForm(exercisesTypes?.content)
})

export enum AppModal {
  // region ---- schedule page
  SCHEDULE_PAGE_MODAL_CREATE = 'SCHEDULE_PAGE_MODAL_CREATE',
  SCHEDULE_PAGE_MODAL_CONFIRM = 'SCHEDULE_PAGE_MODAL_CONFIRM',
  SCHEDULE_PAGE_MODAL_ERROR = 'SCHEDULE_PAGE_MODAL_ERROR',
  // endregion

  // region ---- schedule management page
  SCHEDULE_MANAGEMENT_PAGE_MODAL_CREATE = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_CREATE',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_EDIT = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_EDIT',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_CONFIRM',
  SCHEDULE_MANAGEMENT_PAGE_MODAL_ERROR = 'SCHEDULE_MANAGEMENT_PAGE_MODAL_ERROR',
  // endregion

  // region ---- schedule group page
  SCHEDULE_GROUP_PAGE_MODAL_BOOKING = 'SCHEDULE_GROUP_PAGE_MODAL_BOOKING',
  SCHEDULE_GROUP_PAGE_MODAL_WAITING = 'SCHEDULE_GROUP_PAGE_MODAL_WAITING',
  SCHEDULE_GROUP_PAGE_MODAL_BOOK_FROM_WAITING = 'SCHEDULE_GROUP_PAGE_MODAL_BOOK_FROM_WAITING',
  SCHEDULE_GROUP_PAGE_MODAL_COMMENTS = 'SCHEDULE_GROUP_PAGE_MODAL_COMMENTS',
  SCHEDULE_GROUP_PAGE_MODAL_QR_READER = 'SCHEDULE_GROUP_PAGE_MODAL_QR_READER',
  // endregion

  // region ---- transactions page
  TRANSACTIONS_PAGE_RECEIPTS_MODAL = 'TRANSACTIONS_PAGE_RECEIPTS_MODAL',
  TRANSACTIONS_PAGE_REFUND_MODAL = 'TRANSACTIONS_PAGE_REFUND_MODAL',
  // endregion

  // region ---- transactions create page
  TRANSACTIONS_CREATE_PAGE_MODAL_PRODUCTS = 'TRANSACTIONS_CREATE_PAGE_MODAL_PRODUCTS',
  TRANSACTIONS_CREATE_PAGE_MODAL_CONFIRM = 'TRANSACTIONS_CREATE_PAGE_MODAL_CONFIRM',
  // endregion

  // region ---- clients edit page page
  CLIENTS_EDIT_PAGE_BOOKINGS_ACTIVE_RECEIPTS_MODAL = 'CLIENTS_EDIT_PAGE_BOOKINGS_ACTIVE_RECEIPTS_MODAL',
  CLIENTS_EDIT_PAGE_BOOKINGS_HISTORY_RECEIPTS_MODAL = 'CLIENTS_EDIT_PAGE_BOOKINGS_HISTORY_RECEIPTS_MODAL',
  CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_PAUSE_MODAL = 'CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_PAUSE_MODAL',
  CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_RECEIPTS_MODAL = 'CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_RECEIPTS_MODAL',
  CLIENTS_EDIT_PAGE_PURCHASES_RECEIPTS_MODAL = 'CLIENTS_EDIT_PAGE_PURCHASES_RECEIPTS_MODAL',
  // endregion
  CUSTOM_FIELDS_PAGE_MODAL_EDIT = 'CUSTOM_FIELDS_PAGE_MODAL_EDIT',
  CUSTOM_FIELDS_PAGE_MODAL_CREATE = 'CUSTOM_FIELDS_PAGE_MODAL_CREATE',

  FRANCHISES_PAGE_TERMINAL_FORM = 'FRANCHISES_PAGE_TERMINAL_FORM',

  FRANCHISES_PAGE_TILL_FORM = 'FRANCHISES_PAGE_TILL_FORM',
}

export type AppModalQueueItem<Props extends AppModalBaseProps = AppModalBaseProps> = {
  modal: AppModal
  props?: Props
}

export interface AppModalBaseProps {
  onCancel?: () => void
}

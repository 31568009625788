import * as React from 'react'
import { Button, Space, Tooltip } from 'antd'
import { BarcodeOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { ClientsBookingsTableHistoryActionsEvents } from './clients-bookings-table-history-actions.types'

interface Props extends ClientsBookingsTableHistoryActionsEvents {
  bookingId: string
  exerciseId: string
  studioId: string
}

export const ClientsBookingsTableHistoryActions: React.FC<Props> = props => {
  const { bookingId, exerciseId, studioId } = props
  const { onBarcode, onBooking } = props

  const onBarcodeHandler = React.useCallback((): void => {
    onBarcode(bookingId, exerciseId)
  }, [bookingId, exerciseId, onBarcode])

  const onBookingHandler = React.useCallback((): void => {
    onBooking(studioId, exerciseId)
  }, [exerciseId, onBooking, studioId])

  return (
    <Space size="middle">
      <Tooltip title="Показать чек">
        <Button icon={<BarcodeOutlined />} size="middle" onClick={onBarcodeHandler} />
      </Tooltip>
      <Tooltip title="Перейти к группе">
        <Button icon={<InfoCircleOutlined />} size="middle" onClick={onBookingHandler} />
      </Tooltip>
    </Space>
  )
}

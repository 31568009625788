import * as React from 'react'
import { ColumnsType } from 'antd/lib/table'

import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { MasterServicesTableActions, MasterServicesTableDataItem } from './master-services-table.types'
import { TableCellList } from '../../table-cells/table-cell-list/table-cell-list.component'
import { MasterServicesTableColumnActions } from './master-services-table-actions/master-services-table-actions.component'

export function genMasterServicesTableColumns(
  events: MasterServicesTableActions
): ColumnsType<MasterServicesTableDataItem> {
  const { onEdit, onRemove } = events

  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (_, masterService) => <TableCellText text={String(masterService.name)} />,
    },
    {
      title: 'Студии',
      dataIndex: 'studios',
      key: 'studios',
      render: (_, masterService) => <TableCellList items={masterService.studios} />,
    },
    {
      title: 'Направление',
      dataIndex: 'exerciseType',
      key: 'exerciseType',
      render: (_, masterService) => <TableCellText text={masterService.exerciseType?.title} />,
    },
    {
      title: 'Кол-во подуслуг',
      dataIndex: 'subServicesCount',
      key: 'subServicesCount',
      render: (_, masterService) => <TableCellText text={masterService.subServicesCount?.toString()} />,
    },
    {
      title: 'Кол-во сотрудников',
      dataIndex: 'employeesCount',
      key: 'employeesCount',
      render: (_, masterService) => <TableCellText text={masterService.employeesCount?.toString()} />,
    },
    {
      title: 'Операции',
      key: 'action',
      render: (_, masterService) => (
        <MasterServicesTableColumnActions
          id={masterService.id}
          name={masterService.name}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ),
    },
  ]
}

import { DefaultOptionType } from 'antd/es/select'
import { Rule } from 'antd/lib/form'

export function genCheckRadioOptions() {
  return [
    { label: 'Аванс', value: false },
    { label: 'Услуга оказывается сразу', value: true },
  ]
}

export function genOnlinePaymentOptions() {
  return [
    { label: 'На месте', value: false },
    { label: 'Онлайн', value: true },
  ]
}

export function genTimeSlotDurationOptions(): DefaultOptionType[] {
  return [
    { label: '30 минут', value: 30 },
    { label: '60 минут', value: 60 },
  ]
}

export function genMinBookingDurationMinutesOptions() {
  return [{ label: '30', value: 30 }]
}

export function genMinBookingDurationHoursOptions() {
  return [{ label: '0', value: 0 }]
}

export function genMasterServicesFormNameValidationRules(): Rule[] {
  return [{ required: true, message: 'Укажите название персональной услуги' }]
}

export function genMasterServicesFormExerciseTypeValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите тип записи' }]
}

export function genMasterServicesFormTimeStepMinutesValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите шаг времени' }]
}

export function genMasterServicesFormTimeValidationRules(): Rule[] {
  return [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value < 0) {
          return Promise.reject('Недопустимое значение')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}

export function genMasterServicesFormRestrictionStartTimeValidationRules(): Rule[] {
  return [{ required: true, message: 'Укажите время' }]
}

export function genMasterServicesFormRestrictionMinutesDurationValidationRules(): Rule[] {
  return [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value < 0) {
          return Promise.reject('Недопустимое значение')
        } else {
          return Promise.resolve()
        }
      },
    }),
    { required: true, message: 'Укажите время' },
  ]
}

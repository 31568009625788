import { combineReducers } from '@reduxjs/toolkit'

import { scheduleGroupPageListReducer } from './schedule-group-page-list/schedule-group-page-list.slice'
import { scheduleGroupPageModalBookingReducer } from './schedule-group-page-modal-booking/schedule-group-page-modal-booking.slice'
import { scheduleGroupPageModalWaitingReducer } from './schedule-group-page-modal-waiting/schedule-group-page-modal-waiting.slice'
import { scheduleGroupPageModalCommentsReducer } from './schedule-group-page-modal-comments/schedule-group-page-modal-comments.slice'
import { scheduleGroupPageEditReducer } from './schedule-group-page-edit/schedule-group-page-edit.slice'
import { scheduleGroupPageModalBookFromWaitingReducer } from './schedule-group-page-modal-book-from-waiting/schedule-group-page-modal-book-from-waiting.slice'

export const scheduleGroupPageReducer = combineReducers({
  scheduleGroupPageList: scheduleGroupPageListReducer,
  scheduleGroupPageModalBooking: scheduleGroupPageModalBookingReducer,
  scheduleGroupPageModalBookFromWaiting: scheduleGroupPageModalBookFromWaitingReducer,
  scheduleGroupPageModalWaiting: scheduleGroupPageModalWaitingReducer,
  scheduleGroupPageModalComments: scheduleGroupPageModalCommentsReducer,
  scheduleGroupPageEdit: scheduleGroupPageEditReducer,
})

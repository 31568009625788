import { Button, Col, DatePicker, Form, Input, InputRef, Select } from 'antd'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { CustomFieldsSettingsGenInput } from '../../../components/custom-fields-settings/custom-fields-settings.component'
import { useCustomFieldsSettings } from '../../../components/custom-fields-settings/custom-fields-settings.hook'
import { customFieldsSettingsActions } from '../../../store/common/custom-fields-settings/custom-fields-settings.slice'
import { isDef, NString } from '../../../types/lang.types'
import { PositionType } from '../../../types/positions.types'
import { InputPhoneNumber } from '../../controls/input-phone-number/input-phone-number.component'
import { ImageUploader } from '../../image-uploader/image-uploader.component'
import { useEmployeesForm } from './employees-form.hook'
import { EmployeesFormProps, EmployeesFormValues } from './employees-form.types'
import { genEmployeesFormValidateINN, genEmployeesUpdateFormValuesDTO } from './employees-form.utils'

export const EmployeesForm = (props: EmployeesFormProps) => {
  const {
    form,
    submitText,
    franchisesOptions,
    positionsOptions,
    gradesOptions,
    directionsOptions,
    exercisesTypesOptions,
    employeePositionType,
    customFields,
    onFinish,
    onChangePosition,
  } = props

  const {
    firstNameValidationRules,
    lastNameValidationRules,
    birthDateValidationRules,
    phoneValidationRules,
    emailValidationRules,
    positionValidationRules,
    organisationValidationRules,
  } = useEmployeesForm()

  const {
    handleInputChange,
    formValues,
    setFormValues,
    hasEmptyRequiredField,
    customFieldsWithValue,
    findEmptyRequiredFields,
  } = useCustomFieldsSettings({
    customFields: customFields || null,
  })

  const inputTagRef = useRef<InputRef>(null)

  useEffect(() => {
    if (inputTagRef.current !== null) {
      inputTagRef.current.focus()
    }
  }, [])

  const photo = Form.useWatch('photo', form)

  const onChangeImageHandler = useCallback(
    (photo: NString): void => {
      if (isDef(photo)) {
        const formValues = form.getFieldsValue()
        const formForAdd = {
          ...formValues,
          photo,
        }
        form.setFieldsValue(formForAdd)
      }
    },
    [form]
  )

  const onChangePhoneHandler = (value: string) => {
    form.setFieldValue('phone', value)
  }

  const onChangePositionHandler = useCallback(
    (value: string): void => {
      onChangePosition(value)
      const formValues = form.getFieldsValue()
      form.setFieldsValue({
        ...formValues,
        grade: undefined,
      })
    },
    [form]
  )
  const dispatch = useDispatch()
  const onFinishHandler = (values: EmployeesFormValues): void => {
    if (hasEmptyRequiredField) {
      const errorFields = findEmptyRequiredFields(formValues)
      Object.entries(errorFields).forEach(([name, fieldErrors]) => {
        form.setFields([{ name, errors: fieldErrors.errors }])
      })
    } else {
      const employeesFormValuesDTO = genEmployeesUpdateFormValuesDTO(values)
      onFinish(employeesFormValuesDTO)
      dispatch(customFieldsSettingsActions.setCustomFields([...customFieldsWithValue]))
    }
  }

  const innValueForm = Form.useWatch('inn', form)

  return (
    <Form
      form={form}
      name="userCreate"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinishHandler}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item name="photo" className="avatar_item">
        <ImageUploader image={photo} onChange={onChangeImageHandler} />
      </Form.Item>

      <Col style={{ marginTop: 15 }}>
        <Form.Item label="Фамилия" name="lastName" rules={lastNameValidationRules}>
          <Input placeholder="Фамилия" />
        </Form.Item>
      </Col>

      <Col style={{ marginTop: 15 }}>
        <Form.Item label="Имя" name="firstName" rules={firstNameValidationRules}>
          <Input placeholder="Имя" />
        </Form.Item>
      </Col>

      <Col style={{ marginTop: 15 }}>
        <Form.Item label="Отчество" name="middleName">
          <Input placeholder="Отчество" />
        </Form.Item>
      </Col>

      <Col style={{ marginTop: 15 }}>
        <Form.Item label="Дата рождения" name="birthDate" rules={birthDateValidationRules}>
          <DatePicker style={{ width: '100%' }} placeholder="Дата рождения" />
        </Form.Item>
      </Col>

      {employeePositionType === PositionType.EXECUTOR && (
        <Col style={{ marginTop: 15 }}>
          <Form.Item label="Биография" name="bio">
            <Input.TextArea rows={4} placeholder="Биография" />
          </Form.Item>
        </Col>
      )}

      <Col style={{ marginTop: 15 }}>
        <Form.Item label="Номер телефона" name="phone" rules={phoneValidationRules} validateTrigger={['onBlur']}>
          <InputPhoneNumber
            value={form.getFieldValue('phone')}
            onChange={onChangePhoneHandler}
            onBlur={() => form.validateFields(['phone'])}
            onFocus={() =>
              form.setFields([
                {
                  name: 'phone',
                  errors: [],
                },
              ])
            }
          />
        </Form.Item>
      </Col>
      <Col style={{ marginTop: 15 }}>
        <Form.Item label="Email" name="email" rules={emailValidationRules}>
          <Input placeholder="Email" />
        </Form.Item>
      </Col>
      <Col style={{ marginTop: 15 }}>
        <Form.Item label="Должность" name="position" rules={positionValidationRules}>
          <Select placeholder="Должность" options={positionsOptions} onChange={onChangePositionHandler} />
        </Form.Item>
      </Col>
      {employeePositionType !== PositionType.MANAGER && (
        <Col style={{ marginTop: 15 }}>
          <Form.Item label="Грейд" name="grade">
            <Select placeholder="Грейд" options={gradesOptions} />
          </Form.Item>
        </Col>
      )}

      {employeePositionType === PositionType.EXECUTOR && (
        <Col style={{ marginTop: 15 }}>
          <Form.Item label="Типы записей" name="exerciseTypeIds">
            <Select placeholder="Типы записей" options={exercisesTypesOptions} mode="multiple" />
          </Form.Item>
        </Col>
      )}

      {employeePositionType === PositionType.EXECUTOR && (
        <Col style={{ marginTop: 15 }}>
          <Form.Item label="Направления" name="exerciseDirections">
            <Select placeholder="Направления" options={directionsOptions} mode="multiple" />
          </Form.Item>
        </Col>
      )}

      <Col style={{ marginTop: 15 }}>
        <Form.Item label="Компания" name="organisation" rules={organisationValidationRules}>
          <Select placeholder="Компания" options={franchisesOptions} />
        </Form.Item>
      </Col>

      <Col style={{ marginTop: 15 }}>
        <Form.Item label="ИНН" name="inn" rules={[{ validator: genEmployeesFormValidateINN }]}>
          <Input value={Number(innValueForm)} type="number" maxLength={12} placeholder="ИНН" />
        </Form.Item>
      </Col>
      <CustomFieldsSettingsGenInput customFields={customFields} handleInputChange={handleInputChange} />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {submitText}
        </Button>
      </Form.Item>
    </Form>
  )
}

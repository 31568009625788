import { CopyOutlined, SearchOutlined } from '@ant-design/icons'
import { TableCellTagProductType } from '@components/table-cells/table-cell-tag-product-type/table-cell-tag-product-type.component'
import { formatRublesPenniesCurrency } from '@format/number.format'
import { formatClientsPaymentMethod } from '@format/text.format'
import { Button, Col, Flex, Input, message, Table, Tag, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ExpandedRowRender } from 'rc-table/lib/interface'
import CopyToClipboard from 'react-copy-to-clipboard'

import { formatPhoneNumber } from '../../../format/phone.format'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { firstItem } from '../../../utils/list.utils'
import { TableCellDateTimeTransaction } from '../../table-cells/table-cell-date-transaction/table-cell-date-transaction.component'
import { TableCellPrice } from '../../table-cells/table-cell-price/table-cell-price.component'
import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { TransactionsTableActions } from './transactions-table-actions/transactions-table-actions.component'
import { TransactionsTableActionsEvents } from './transactions-table-actions/transactions-table-actions.types'
import { TransactionsTableStatus } from './transactions-table-status/transactions-table-status.component'
import { TransactionsTableDataItem, TransactionsTableDataItemProduct } from './transactions-table.types'

export function genTransactionsTableColumns(
  events: TransactionsTableActionsEvents,
  onPhoneClick: (clientId: string) => void
): ColumnsType<TransactionsTableDataItem> {
  const { onBarcode, onRefundSum } = events
  return [
    {
      title: 'Продажа',
      key: 'products',
      width: 80,
      filters: [
        { text: 'Товар', value: 'goods' },
        { text: 'Разовые услуги', value: 'oneService' },
        { text: 'Персональные услуги', value: 'personal' },
        { text: 'Абонемент', value: 'SUBSCRIPTION' },
      ],
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Поиск товара"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: '100%', marginBottom: 8 }}
          >
            Поиск
          </Button>
          <Button onClick={clearFilters && (() => clearFilters())} size="small" style={{ width: '100%' }}>
            Сбросить
          </Button>
        </div>
      ),
      // onFilter: (value, record) => {
      //   switch (value) {
      //     case 'goods':
      //       return record.products.some(product => product.type === 'GOODS')
      //     case 'oneService':
      //       return record.products.some(
      //         product => product.type === 'SERVICE' || product.type === 'FULL_PAYMENT_SERVICE'
      //       )
      //     case 'personal':
      //       return record.products.some(
      //         product => product.type === 'ADVANCE_SUB_SERVICE' || product.type === 'INSTANT_SUB_SERVICE'
      //       )
      //     case 'SUBSCRIPTION':
      //       return record.products.some(product => product.type === 'SUBSCRIPTION')
      //     default:
      //       return false
      //   }
      // },
      onFilter: (value, record) => {
        const filterValue = String(value).toLowerCase()
        return record.products.some(product => product.name.toLowerCase().includes(filterValue))
      },

      sorter: (a, b) => {
        const productNameA = firstItem(a.products)?.name || ''
        const productNameB = firstItem(b.products)?.name || ''
        return productNameA.localeCompare(productNameB)
      },
      render: (_, transaction) => {
        const { products } = transaction

        if (products.length > 1) {
          const text = `Товар, ${products.length} шт`

          return (
            <>
              <TableCellDateTimeTransaction date={transaction.date} format="HH:mm" />
              <TableCellText text={text} />
              <Col style={{ paddingTop: 4 }}>
                <Tag color="pink">Товары</Tag>
              </Col>
            </>
          )
        }

        return (
          <>
            <TableCellDateTimeTransaction date={transaction.date} format="HH:mm" />
            <Typography>{firstItem(products)?.name}</Typography>
            <Col style={{ paddingTop: 4 }}>
              <TableCellTagProductType productType={transaction.products[0].type} />
            </Col>
          </>
        )
      },
    },
    {
      title: 'Сотрудник',
      key: 'seller',
      width: 50,
      render: (_, transaction) => {
        const sellerName = transaction.seller ? `${transaction.seller.lastName} ${transaction.seller.firstName}` : '-'
        return <TableCellText text={sellerName} />
      },
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Поиск сотрудника"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: '100%', marginBottom: 8 }}
          >
            Поиск
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: '100%' }}>
            Сбросить
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        if (!record.seller) return false // Check if seller is null
        const fullName = `${record.seller.lastName} ${record.seller.firstName}`.toLowerCase()
        const filterValue = String(value).toLowerCase()
        return fullName.includes(filterValue)
      },
    },
    {
      title: 'Клиент',
      dataIndex: 'phone',
      key: 'phone',
      width: 190,
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Поиск клиента"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: '100%', marginBottom: 8 }}
          >
            Поиск
          </Button>
          <Button onClick={clearFilters} size="small" style={{ width: '100%' }}>
            Сбросить
          </Button>
        </div>
      ),
      onFilter: (value, record) => {
        const clientPhone = `+${record.phone.toLowerCase()}`
        const filterValue = String(value).toLowerCase()
        return clientPhone.includes(filterValue)
      },
      render: (_, transaction) => {
        const phone = `+${transaction.phone}`
        return (
          <Flex gap={8} align="center">
            <Typography
              onClick={() => {
                onPhoneClick(transaction.client.id)
              }}
              style={{ color: '#1677ff', cursor: 'pointer' }}
            >
              {formatPhoneNumber(phone)}
            </Typography>
            <Tooltip title="Скопировать номер телефона">
              <CopyToClipboard text={phone}>
                <Button
                  onClick={() => {
                    message.success('Номер телефона скопирован')
                  }}
                  icon={<CopyOutlined />}
                  size="middle"
                />
              </CopyToClipboard>
            </Tooltip>
          </Flex>
        )
      },
    },

    {
      title: 'Стоимость',
      dataIndex: 'toPay',
      key: 'toPay',
      width: 60,
      sorter: (a, b) => a.toPay - b.toPay,
      render: (_, transaction) => (
        <Col>
          {!!transaction.discount && (
            <Tooltip title={<span>{transaction.discountReason}</span>}>
              <span
                style={{
                  color: '#FF7875',
                  fontSize: 12,
                  textDecoration: 'line-through',
                }}
              >
                {formatRublesPenniesCurrency(transaction.sum)}
              </span>
            </Tooltip>
          )}
          <div>
            <TableCellPrice value={transaction.toPay} />
          </div>
        </Col>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      width: 60,
      filters: [
        { text: 'Оплачено', value: 'PAID' },
        { text: 'Не оплачено', value: 'UNPAID' },
        { text: 'Ожидание', value: 'WAITING' },
        { text: 'Возврат', value: 'REFUND' },
      ],
      onFilter: (value, transaction) => transaction.status === value,
      render: (_, transaction) => <TransactionsTableStatus value={transaction.status} />,
    },
    {
      title: 'Метод оплаты',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      width: 50,
      filters: [
        { text: 'Оплата картой', value: 'CARD' },
        { text: 'Наличные', value: 'CASH' },
        { text: 'СМС / Ссылка', value: 'SMS' },
        { text: 'Через приложение', value: 'APP' },
        { text: 'Виджет', value: 'WIDGET' },
        { text: 'Без оплаты', value: 'NO_PAYMENT' },
      ],
      onFilter: (value, transaction) => transaction.paymentMethod === value,
      sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
      render: (_, transaction) => {
        const url = transaction.cardPaymentInfo && transaction.cardPaymentInfo.paymentUrl
        return <TableCellText text={formatClientsPaymentMethod(transaction.paymentMethod)} />
      },
    },
    {
      title: 'Операции',
      key: 'action',
      width: 60,
      render: (_, transaction) => (
        <TransactionsTableActions transaction={transaction} onBarcode={onBarcode} onRefundSum={onRefundSum} />
      ),
    },
  ]
}

export function genTransactionsTableProductsColumns(): ColumnsType<TransactionsTableDataItemProduct> {
  return [
    {
      title: 'Наименование',
      key: 'products',
      render: (_, product) => <TableCellText text={product.name} />,
    },
    {
      title: 'Стоимость',
      dataIndex: 'sum',
      key: 'sum',
      render: (_, product) => <TableCellPrice value={product.cost} />,
    },
    {
      title: 'Скидка',
      dataIndex: 'discount',
      key: 'discount',
      render: (_, product) => <TableCellText text={String(product.discount)} />,
    },
    {
      title: 'Кол-во',
      dataIndex: 'count',
      key: 'count',
      render: (_, product) => <TableCellText text={String(product.count)} />,
    },
    {
      title: 'Сумма',
      key: 'total',
      render: (_, product) => {
        const total = (product.cost - product.discount) * product.count

        return <TableCellPrice value={total} />
      },
    },
  ]
}

export const genTransactionsTableExpandedRowRender: ExpandedRowRender<TransactionsTableDataItem> = (
  record: TransactionsTableDataItem
) => {
  const columns = genTransactionsTableProductsColumns()

  return <Table dataSource={record.products} columns={columns} pagination={false} loading={false} />
}

export const genTransactionsTableRowExpandable = (transaction: TransactionsTableDataItem): boolean => {
  return isDefAndNotEmpty(transaction.products) && transaction.products.length > 1
}
